import React from "react";
import { Button, Space, Table } from "antd";
import "antd/dist/antd.css";
import FormEditDetailJo from "./form-edit-detail-jo";
import { useDispatch, useSelector } from "react-redux";
import TambahJobOrder from "../../../../application/selectors/tambahjoborder";
import {
  deleteJO,
  editDataDetailJO,
  setIsEditTambahJO,
  showEditTambahJO,
} from "../../../../application/actions/tambahjoborder";
import Swal from "sweetalert2";

const TableTambahJO = () => {
  const dispatch = useDispatch();
  const dataDetailJO = JSON.parse(localStorage.getItem("data_detail_jo")) || [];
  const isEdit = useSelector(TambahJobOrder.getIsEditJO);
  const onDelete = (kode, nama) => {
    Swal.fire({
      title: nama,
      text: "Apakah Anda Yakin Akan Mengahapus Data Ini ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteJO(kode));
      }
    });
  };

  const columns = [
    {
      title: "No Job Order",
      dataIndex: "no_job_order",
      key: "no_job_order",
      align: "center",
    },
    {
      title: "Kode Barang",
      dataIndex: "kode_barang",
      key: "kode_barang",
      align: "center",
    },
    {
      title: "Jenis Bahan",
      dataIndex: "kode_jenis_bahan",
      key: "kode_jenis_bahan",
      align: "center",
    },
    // {
    //   title: "Stock Order",
    //   dataIndex: "stock_order",
    //   key: "stock_order",
    //   align: "center",
    // },
    {
      title: "Jumlah",
      dataIndex: "jumlah",
      key: "jumlah",
      align: "center",
      render: (text) => {
        return <span style={{ textAlign: "right" }}>{text}</span>;
      },
    },
    {
      title: "Berat Terima Potong",
      dataIndex: "berat_potong",
      key: "berat_potong",
      align: "center",
      render: (text) => {
        return <span style={{ textAlign: "right" }}>{text}</span>;
      },
    },
    {
      title: "Berat",
      dataIndex: "berat",
      key: "berat",
      align: "center",
      render: (text) => {
        return <span style={{ textAlign: "right" }}>{text}</span>;
      },
    },
    {
      title: "Berat Balik",
      dataIndex: "berat_balik",
      key: "berat_balik",
      align: "center",
      render: (text) => {
        return <span style={{ textAlign: "right" }}>{text}</span>;
      },
    },
    {
      title: "Catatan",
      dataIndex: "catatan",
      key: "catatan",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "kode_status_job_order",
      key: "kode_status_job_order",
      align: "center",
    },
    {
      title: "Action",
      key: "act",
      align: "center",
      render: (text) => {
        return (
          <>
            <Space>
              <Button
                className="ant-btn-warning"
                htmltype="button"
                danger
                onClick={() => {
                  dispatch(showEditTambahJO(text));
                }}
              >
                EDIT
              </Button>
              <Button
                type="primary"
                htmltype="button"
                danger
                onClick={() => {
                  onDelete(text.no_job_order, text.no_job_order);
                }}
              >
                DELETE
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <FormEditDetailJo
        visible={isEdit}
        onCreate={() => {
          dispatch(editDataDetailJO);
        }}
        onCancel={() => {
          dispatch(setIsEditTambahJO(false));
        }}
      />
      <Table
        dataSource={dataDetailJO}
        columns={columns}
        scroll={{ x: 500, y: 1500 }}
      />
    </>
  );
};

export default TableTambahJO;
