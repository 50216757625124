import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import { Card, Divider } from "antd";
import { pageLoadedLogin } from "../../../../application/actions/ui";
import FormLaporanJobOrder from "../../../components/laporan/laporan-admin-pusat/laporan-job-order/form-laporan-jo";
import TableLaporanJobOrder from "../../../components/laporan/laporan-admin-pusat/laporan-job-order/table-laporan-jo";
import BtnPrint from "../../../components/laporan/laporan-admin-pusat/laporan-job-order/btn-print-excel-pdf";
import laporanjoborder from "../../../../application/selectors/laporanjoborder";
import masterdivisi from "../../../../application/selectors/masterdivisi";
import KirimJO from "../../../../application/selectors/kirimjo";
import { getTukangByDivisi } from "../../../../application/actions/kirimjo";
import { change } from "redux-form";

const LaporanJobOrder = () => {
  const dispatch = useDispatch();
  const dataDivisi = useSelector(masterdivisi.getAllMasterDivisi);
  const dataTukang = useSelector(KirimJO.getDataTukang);
  useEffect(() => {
    dispatch(pageLoadedLogin);
    dispatch(getTukangByDivisi(dataDivisi[0]?.divisi));
    document.title = "Laporan Job Order Divisi";
  }, [dispatch, dataDivisi]);

  useEffect(() => {
    dispatch(
      change("FormLaporanJobOrder", "kode_tukang", dataTukang[0]?.nama_tukang)
    );
  }, [dispatch, dataTukang]);

  useEffect(() => {
    dispatch(change("FormLaporanJobOrder", "divisi", dataDivisi[0]?.divisi));
  }, [dispatch, dataDivisi]);

  const dataLap = useSelector(laporanjoborder.getAllLaporanJobOrder);

  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/laporan/admin-pusat/job-order-divisi">
            Laporan Admin Pusat
          </Link>
        </li>
        <li className="breadcrumb-item active">Job Order Divisi</li>
      </ol>
      <h1 className="page-header">
        Laporan Admin Pusat <small>Job Order Divisi</small>
      </h1>
      <Panel>
        <PanelHeader>Job Order Divisi</PanelHeader>
        <PanelBody>
          <Card bordered={false}>
            <div className="row">
              <div className="col-12">
                <FormLaporanJobOrder />
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: 10,
                display: dataLap.length === 0 ? "none" : "",
              }}
            >
              <div className="col-12">
                <Divider orientation="left" style={{ fontSize: "14px" }}>
                  Tabel Laporan
                </Divider>
              </div>
              <div className="col-12">
                <TableLaporanJobOrder />
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: 10,
                display: dataLap.length === 0 ? "none" : "",
              }}
            >
              <div className="col-12">
                <BtnPrint />
              </div>
            </div>
          </Card>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default LaporanJobOrder;
