export const GET_DETAIL_BATU_BY_KODE = "[kirimbatucor] get detail batu by kode";
export const GET_DATA_BATU_LOCAL = "[kirimbatucor] get data batu local";
export const SET_DATA_BATU = "[kirimbatucor] set data batu local";
export const SAVE_BATU_LOCAL = "[kirimbatucor] save batu local";
export const POST_KIRIM_BATU_COR = "[kirimbatucor] post kirim batu cor";

export const getDetailBatuByKode = ({ kodeBatu }) => ({
  type: GET_DETAIL_BATU_BY_KODE,
  payload: { data: kodeBatu },
});

export const getDataBatuLocal = {
  type: GET_DATA_BATU_LOCAL,
};

export const setDataBatuLocal = ({ feedback }) => ({
  type: SET_DATA_BATU,
  payload: { data: feedback },
});

export const simpanBatuLocal = {
  type: SAVE_BATU_LOCAL,
};

export const kirimBatuCOR = {
  type: POST_KIRIM_BATU_COR,
};
