const getAllMasterOriginal = (state) => state.masteroriginal.feedback;
const getErrorMasterOriginal = (state) => state.masteroriginal.error;
const getIsEditMasterOriginal = (state) => state.masteroriginal.isEdit;
const getIsVisibleMasterOriginal = (state) => state.masteroriginal.isVisible;
const data = {
  getAllMasterOriginal,
  getErrorMasterOriginal,
  getIsEditMasterOriginal,
  getIsVisibleMasterOriginal,
};
export default data;
