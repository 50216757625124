import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Form, Row, Col, Modal, Select } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, change, reduxForm } from "redux-form";
import styleAntd from "../../../../infrastructure/shared/styleAntd";
import ui from "../../../../application/selectors/ui";
import {
  addDataTerimaCor,
  countSusut,
  countSusutBatuLepas,
  countSusutBatuRusak,
  getAllDetailPohon,
  setBahan,
  setDataJumlahBatuLepas,
  setDataJumlahBatuRusak,
} from "../../../../application/actions/terimacor";
import JenisBahan from "../../../../application/selectors/masterbahan";
import { io } from "socket.io-client";
import { server } from "../../helpers/library";
// import TerimaCOR from "../../../../application/selectors/terimacor";

const maptostate = (state) => {
  if (state.terimacor.feedback.length !== 0) {
    return {
      initialValues: {
        pohon: state.terimacor.noPohon,
        kode_jenis_bahan: state.terimacor.bahan,
        real_kode_jenis_bahan: state.terimacor.feedback[0]?.kode_jenis_bahan,
        berat_bahan: state.terimacor.feedback[0]?.berat,
        berat_batu: state.terimacor.feedback[0]?.total_berat_batu,
        berat: (
          state.terimacor.feedback[0]?.berat +
          state.terimacor.feedback[0]?.total_berat_batu
        )?.toFixed(3),
        berat_terima: state.terimacor.beratTerima,
        berat_susut: state.terimacor.susut,
        jumlah_batu_lepas: state.terimacor.jumlahLepas,
        berat_batu_lepas: state.terimacor.beratLepas,
        jumlah_batu_rusak: state.terimacor.jumlahRusak,
        berat_batu_rusak: state.terimacor.beratRusak,
      },
    };
  } else {
    return {
      initialValues: {
        jumlah_batu_lepas: "",
        berat_batu_lepas: "",
        jumlah_batu_rusak: "",
        berat_batu_rusak: "",
        pohon: "",
        kode_jenis_bahan: state.masterbahan.feedback[0]?.nama_bahan,
        real_kode_jenis_bahan: "",
        berat: "",
        berat_bahan: "",
        berat_batu: "",
        berat_terima: "",
        berat_susut: "",
      },
    };
  }
};

const { Option } = Select;

let FormTerimaCOR = ({ visible, onCancel }, prop) => {
  const btnLoading = useSelector(ui.getBtnLoading);
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const dataJenisBahan = useSelector(JenisBahan.getAllMasterBahan);
  // const data = useSelector(TerimaCOR.getDetailPohon);
  // const detailBahan = data[0]?.detail_bahan;
  useEffect(() => {
    const dataUser = JSON.parse(localStorage.getItem("userInfo"));
    const socket = io.connect(server.replace("/api/v1/", ""));
    socket.on("timbangan-emit", (data) => {
      if (dataUser.user_id === data.user_id) {
        if (window.document.activeElement.name === "berat_terima") {
          dispatch(change("FormTerimaCOR", "berat_terima", data.value));
          dispatch(countSusut({ beratTerima: data.value }));
        }
        if (window.document.activeElement.name === "berat_batu_lepas") {
          dispatch(change("FormTerimaCOR", "berat_batu_lepas", data.value));
          dispatch(countSusutBatuLepas({ batuLepas: data.value }));
        }
        if (window.document.activeElement.name === "berat_batu_rusak") {
          dispatch(change("FormTerimaCOR", "berat_batu_rusak", data.value));
          dispatch(countSusutBatuRusak({ batuRusak: data.value }));
        }
      }
    });
    return () => {
      const socket = io.connect(server.replace("/api/v1/", ""));
      socket.emit("end-socket", () => {});
    };
  }, [dispatch]);

  return (
    <Modal
      visible={visible}
      title="Detail Pohon"
      okText="Tambah"
      cancelText="Batal"
      confirmLoading={btnLoading}
      onCancel={onCancel}
      onOk={() => {
        dispatch(addDataTerimaCor);
      }}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Field
              name="pohon"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Nomor Pohon</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Nomor Pohon"
              onBlur={(e) => {
                dispatch(getAllDetailPohon({ noPohon: e.target.value }));
              }}
            />
          </Col>

          <Col span={12}>
            <Field
              showSearch
              name="kode_jenis_bahan"
              label={<span style={{ fontSize: "13px" }}>Bahan</span>}
              component={styleAntd.ASelect}
              placeholder="Pilih Bahan"
              onBlur={(e) => e.preventDefault()}
              onChange={(e) => dispatch(setBahan(e))}
            >
              {/* {data.length === 0
                ? dataJenisBahan.map((item) => {
                    return (
                      <Option value={item.nama_bahan} key={item.kode_bahan}>
                        <span style={{ fontSize: "13px" }}>
                          {item.nama_bahan}
                        </span>
                      </Option>
                    );
                  })
                : detailBahan.map((item) => {
                    return (
                      <Option value={item.nama_bahan} key={item.kode_bahan}>
                        <span style={{ fontSize: "13px" }}>
                          {item.nama_bahan}
                        </span>
                      </Option>
                    );
                  })} */}
              {dataJenisBahan.map((item) => {
                return (
                  <Option value={item.nama_bahan} key={item._id}>
                    <span style={{ fontSize: "13px" }}>{item.nama_bahan}</span>
                  </Option>
                );
              })}
            </Field>
          </Col>

          <Col span={12} style={{ display: "none" }}>
            <Field
              name="real_kode_jenis_bahan"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Kode Jenis Bahan</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Kode Jenis Bahan"
              disabled
            />
          </Col>
          <Col span={12}>
            <Field
              name="berat_bahan"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Berat Bahan</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Bahan"
              disabled
            />
          </Col>
          <Col span={12}>
            <Field
              name="berat_batu"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Berat Batu</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Batu"
              disabled
            />
          </Col>
          <Col span={12}>
            <Field
              name="berat"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Berat Awal</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Awal"
              disabled
            />
          </Col>
          <Col span={12}>
            <Field
              name="berat_terima"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Berat Terima</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Terima"
              onBlur={(e) => {
                dispatch(countSusut({ beratTerima: e.target.value }));
              }}
            />
          </Col>
          <Col span={12}>
            <Field
              name="jumlah_batu_lepas"
              type="text"
              label={
                <span style={{ fontSize: "13px" }}>Jumlah Batu Lepas</span>
              }
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Jumlah Batu Lepas"
              onChange={(e) =>
                dispatch(setDataJumlahBatuLepas({ batuLepas: e.target.value }))
              }
            />
          </Col>
          <Col span={12}>
            <Field
              name="berat_batu_lepas"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Berat Batu Lepas</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Batu Lepas"
              onBlur={(e) => {
                dispatch(countSusutBatuLepas({ batuLepas: e.target.value }));
              }}
            />
          </Col>
          <Col span={12}>
            <Field
              name="jumlah_batu_rusak"
              type="text"
              label={
                <span style={{ fontSize: "13px" }}>Jumlah Batu Rusak</span>
              }
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Jumlah Batu Rusak"
              onChange={(e) =>
                dispatch(setDataJumlahBatuRusak({ batuRusak: e.target.value }))
              }
            />
          </Col>
          <Col span={12}>
            <Field
              name="berat_batu_rusak"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Berat Batu Rusak</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Batu Rusak"
              onBlur={(e) => {
                dispatch(countSusutBatuRusak({ batuRusak: e.target.value }));
              }}
            />
          </Col>

          <Col span={12}>
            <Field
              name="berat_susut"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Berat Katekan</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Katekan"
              disabled
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

FormTerimaCOR = reduxForm({
  form: "FormTerimaCOR",
  enableReinitialize: true,
})(FormTerimaCOR);
export default connect(maptostate, null)(FormTerimaCOR);
