import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Form, Row, Col, Modal, Select } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, change, reduxForm } from "redux-form";
import styleAntd from "../../../infrastructure/shared/styleAntd";
import ui from "../../../application/selectors/ui";
import KirimBahanAdmin from "../../../application/selectors/kirimbahanadmin";
// import Tukang from "../../../application/selectors/mastertukang";
import KirimJO from "../../../application/selectors/kirimjo";
import MasterBahan from "../../../application/selectors/masterbahan";
import {
  addLocalKirimJO,
  countBeratBalik,
  countBeratBatuAkhir,
  countBeratKirimJO,
  countQtyBatuAkhir,
  getDataByNoInduk,
  getDataDetailJO,
  getTukangByDivisi,
  saveEditJobOrder,
  setBahanKembaliKirim,
  setTukangTujuan,
  simpanJumlahKirim,
} from "../../../application/actions/kirimjo";
import { io } from "socket.io-client";
import { server } from "../helpers/library";

const { Option } = Select;

const maptostate = (state) => {
  if (state.kirimjo.dataEditJO !== undefined) {
    return {
      initialValues: {
        divisi_tujuan: state.kirimjo.dataEditJO.divisi_tujuan,
        tukang_tujuan:
          state.kirimjo.tukangTujuan !== undefined
            ? state.kirimjo.tukangTujuan
            : state.kirimjo.dataEditJO.tukang_tujuan,
        no_job_order: state.kirimjo.dataEditJO.no_job_order,
        tukang_asal: state.kirimjo.dataEditJO.tukang_asal,
        kode_barang: state.kirimjo.dataEditJO.kode_barang,
        nama_barang: state.kirimjo.dataEditJO.nama_barang,
        kode_jenis_bahan: state.kirimjo.dataEditJO.kode_jenis_bahan,
        jumlah_akhir: state.kirimjo.dataEditJO.jumlah_akhir,
        berat_akhir: state.kirimjo.dataEditJO.berat_akhir,
        berat_batu: state.kirimjo.dataEditJO.berat_batu_tambahan,
        qty_batu: state.kirimjo.dataEditJO.qty_batu_tambahan,
        berat_bahan_tambahan: state.kirimjo.dataEditJO.berat_bahan_tambahan,
        qty_batu_lepas:
          state.kirimjo.qty_batu_lepas === 0
            ? ""
            : state.kirimjo.qty_batu_lepas,
        berat_batu_lepas:
          state.kirimjo.berat_batu_lepas === 0
            ? ""
            : state.kirimjo.berat_batu_lepas,
        qty_batu_rusak:
          state.kirimjo.qty_batu_rusak === 0
            ? ""
            : state.kirimjo.qty_batu_rusak,
        berat_batu_rusak:
          state.kirimjo.berat_batu_rusak === 0
            ? ""
            : state.kirimjo.berat_batu_rusak,
        qty_batu_akhir: state.kirimjo.qty_batu_akhir,
        berat_batu_akhir: state.kirimjo.berat_batu_akhir,
        qty_batu_sisa_akhir: state.kirimjo.dataDetailJO[0]?.qty_batu_akhir,
        berat_batu_sisa_akhir: state.kirimjo.dataDetailJO[0]?.berat_batu_akhir,
        susut: state.kirimjo.beratSusut,
        jumlah_kirim: state.kirimjo.dataEditJO.jumlah_kirim,
        berat_kirim:
          state.kirimjo.beratKirim === 0 ? "" : state.kirimjo.beratKirim,
        berat_balik:
          state.kirimjo.beratBalik === 0 ? "" : state.kirimjo.beratBalik,
        no_induk_job_order: state.kirimjo.dataEditJO.no_induk_job_order,
        bahan_kembali: state.kirimjo.bahan_kembali,
      },
    };
  } else {
    if (state.kirimjo.NoIndukJO !== undefined) {
      if (state.kirimjo.detailJO.length !== 0) {
        if (state.kirimjo.dataDetailJO.length !== 0) {
          return {
            initialValues: {
              divisi_tujuan: state.kirimjo.divisiTujuan,
              tukang_tujuan:
                state.kirimjo.tukangTujuan !== undefined
                  ? state.kirimjo.tukangTujuan
                  : state.kirimjo.dataTukang[0]?.nama_tukang,
              no_job_order: state.kirimjo.dataDetailJO[0]?.no_job_order,
              tukang_asal: state.kirimjo.dataDetailJO[0]?.kode_tukang,
              kode_barang: state.kirimjo.dataDetailJO[0]?.kode_barang,
              nama_barang: state.kirimjo.dataDetailJO[0]?.nama_barang,
              kode_jenis_bahan: state.kirimjo.dataDetailJO[0]?.kode_jenis_bahan,
              jumlah_akhir: state.kirimjo.dataDetailJO[0]?.stock_akhir,
              berat_akhir: state.kirimjo.dataDetailJO[0]?.berat_akhir,
              berat_bahan_tambahan:
                state.kirimjo.dataDetailJO[0]?.berat_bahan_tambahan || 0,
              berat_batu: state.kirimjo.dataDetailJO[0]?.berat_batu || 0,
              qty_batu: state.kirimjo.dataDetailJO[0]?.qty_batu || 0,
              qty_batu_lepas:
                state.kirimjo.qty_batu_lepas === 0
                  ? ""
                  : state.kirimjo.qty_batu_lepas,
              berat_batu_lepas:
                state.kirimjo.berat_batu_lepas === 0
                  ? ""
                  : state.kirimjo.berat_batu_lepas,
              qty_batu_rusak:
                state.kirimjo.qty_batu_rusak === 0
                  ? ""
                  : state.kirimjo.qty_batu_rusak,
              berat_batu_rusak:
                state.kirimjo.berat_batu_rusak === 0
                  ? ""
                  : state.kirimjo.berat_batu_rusak,
              qty_batu_sisa_akhir:
                state.kirimjo.dataDetailJO[0]?.qty_batu_akhir,
              berat_batu_sisa_akhir:
                state.kirimjo.dataDetailJO[0]?.berat_batu_akhir,
              qty_batu_akhir: state.kirimjo.qty_batu_akhir,
              berat_batu_akhir: state.kirimjo.berat_batu_akhir,
              susut: state.kirimjo.beratSusut,
              jumlah_kirim: state.kirimjo.dataDetailJO[0]?.stock_akhir,
              berat_kirim:
                state.kirimjo.beratKirim === 0 ? "" : state.kirimjo.beratKirim,
              berat_balik:
                state.kirimjo.beratBalik === 0 ? "" : state.kirimjo.beratBalik,
              no_induk_job_order: state.kirimjo.NoIndukJO,
              bahan_kembali: state.kirimjo.bahan_kembali,
            },
          };
        } else {
          return {
            initialValues: {
              divisi_tujuan: state.kirimjo.divisiTujuan,
              tukang_tujuan:
                state.kirimjo.tukangTujuan !== undefined
                  ? state.kirimjo.tukangTujuan
                  : state.kirimjo.dataTukang[0]?.nama_tukang,
              no_job_order: state.kirimjo.detailJO[0]?.no_job_order,
              tukang_asal: state.kirimjo.dataDetailJO[0]?.kode_tukang,
              kode_barang: state.kirimjo.dataDetailJO[0]?.kode_barang,
              nama_barang: state.kirimjo.dataDetailJO[0]?.nama_barang,
              kode_jenis_bahan: state.kirimjo.dataDetailJO[0]?.kode_jenis_bahan,
              jumlah_akhir: state.kirimjo.dataDetailJO[0]?.stock_akhir,
              berat_akhir: state.kirimjo.dataDetailJO[0]?.berat_akhir,
              berat_batu: state.kirimjo.dataDetailJO[0]?.berat_batu || 0,
              qty_batu: state.kirimjo.dataDetailJO[0]?.qty_batu || 0,
              qty_batu_lepas:
                state.kirimjo.qty_batu_lepas === 0
                  ? ""
                  : state.kirimjo.qty_batu_lepas,
              berat_batu_lepas:
                state.kirimjo.berat_batu_lepas === 0
                  ? ""
                  : state.kirimjo.berat_batu_lepas,
              qty_batu_rusak:
                state.kirimjo.qty_batu_rusak === 0
                  ? ""
                  : state.kirimjo.qty_batu_rusak,
              berat_batu_rusak:
                state.kirimjo.berat_batu_rusak === 0
                  ? ""
                  : state.kirimjo.berat_batu_rusak,
              qty_batu_sisa_akhir:
                state.kirimjo.dataDetailJO[0]?.qty_batu_akhir,
              berat_batu_sisa_akhir:
                state.kirimjo.dataDetailJO[0]?.berat_batu_akhir,
              qty_batu_akhir: state.kirimjo.qty_batu_akhir,
              berat_batu_akhir: state.kirimjo.berat_batu_akhir,
              berat_bahan_tambahan:
                state.kirimjo.dataDetailJO[0]?.berat_bahan_tambahan || 0,
              susut: state.kirimjo.beratSusut,
              jumlah_kirim: state.kirimjo.dataDetailJO[0]?.stock_akhir,
              berat_kirim:
                state.kirimjo.beratKirim === 0 ? "" : state.kirimjo.beratKirim,
              berat_balik:
                state.kirimjo.beratBalik === 0 ? "" : state.kirimjo.beratBalik,
              no_induk_job_order: state.kirimjo.NoIndukJO,
              bahan_kembali: state.kirimjo.bahan_kembali,
            },
          };
        }
      } else {
        return {
          initialValues: {
            divisi_tujuan: state.kirimjo.divisiTujuan,
            tukang_tujuan:
              state.kirimjo.tukangTujuan !== undefined
                ? state.kirimjo.tukangTujuan
                : state.kirimjo.dataTukang[0]?.nama_tukang,
            no_job_order: "",
            tukang_asal: "",
            kode_barang: "",
            nama_barang: "",
            kode_jenis_bahan: "",
            jumlah_akhir: 0,
            berat_akhir: 0,
            berat_batu: 0,
            qty_batu: 0,
            qty_batu_lepas: 0,
            berat_batu_lepas: 0,
            qty_batu_rusak: 0,
            berat_batu_rusak: 0,
            qty_batu_sisa_akhir: 0,
            berat_batu_sisa_akhir: 0,
            qty_batu_akhir: 0,
            berat_batu_akhir: 0,
            berat_bahan_tambahan: 0,
            susut: 0,
            jumlah_kirim: 0,
            berat_kirim: 0,
            berat_balik: 0,
            no_induk_job_order: state.kirimjo.NoIndukJO,
            bahan_kembali: state.kirimjo.bahan_kembali,
          },
        };
      }
    } else {
      if (state.kirimjo.dataDetailJO.length !== 0) {
        return {
          initialValues: {
            divisi_tujuan: state.kirimjo.divisiTujuan,
            tukang_tujuan:
              state.kirimjo.tukangTujuan !== undefined
                ? state.kirimjo.tukangTujuan
                : state.kirimjo.dataTukang[0]?.nama_tukang,
            no_job_order: state.kirimjo.dataDetailJO[0]?.no_job_order,
            tukang_asal: state.kirimjo.dataDetailJO[0]?.kode_tukang,
            kode_barang: state.kirimjo.dataDetailJO[0]?.kode_barang,
            nama_barang: state.kirimjo.dataDetailJO[0]?.nama_barang,
            kode_jenis_bahan: state.kirimjo.dataDetailJO[0]?.kode_jenis_bahan,
            jumlah_akhir: state.kirimjo.dataDetailJO[0]?.stock_akhir,
            berat_akhir: state.kirimjo.dataDetailJO[0]?.berat_akhir,
            berat_batu: state.kirimjo.dataDetailJO[0]?.berat_batu || 0,
            qty_batu: state.kirimjo.dataDetailJO[0]?.qty_batu || 0,
            berat_bahan_tambahan:
              state.kirimjo.dataDetailJO[0]?.berat_bahan_tambahan || 0,
            qty_batu_lepas:
              state.kirimjo.qty_batu_lepas === 0
                ? ""
                : state.kirimjo.qty_batu_lepas,
            berat_batu_lepas:
              state.kirimjo.berat_batu_lepas === 0
                ? ""
                : state.kirimjo.berat_batu_lepas,
            qty_batu_rusak:
              state.kirimjo.qty_batu_rusak === 0
                ? ""
                : state.kirimjo.qty_batu_rusak,
            berat_batu_rusak:
              state.kirimjo.berat_batu_rusak === 0
                ? ""
                : state.kirimjo.berat_batu_rusak,
            qty_batu_sisa_akhir: state.kirimjo.dataDetailJO[0]?.qty_batu_akhir,
            berat_batu_sisa_akhir:
              state.kirimjo.dataDetailJO[0]?.berat_batu_akhir,
            qty_batu_akhir: state.kirimjo.qty_batu_akhir,
            berat_batu_akhir: state.kirimjo.berat_batu_akhir,
            susut: state.kirimjo.beratSusut,
            jumlah_kirim: state.kirimjo.dataDetailJO[0]?.stock_akhir,
            berat_kirim:
              state.kirimjo.beratKirim === 0 ? "" : state.kirimjo.beratKirim,
            berat_balik:
              state.kirimjo.beratBalik === 0 ? "" : state.kirimjo.beratBalik,
            no_induk_job_order:
              state.kirimjo.dataDetailJO[0]?.no_induk_job_order,
            bahan_kembali: state.kirimjo.bahan_kembali,
          },
        };
      } else {
        return {
          initialValues: {
            divisi_tujuan: state.kirimjo.divisiTujuan,
            tukang_tujuan:
              state.kirimjo.tukangTujuan !== undefined
                ? state.kirimjo.tukangTujuan
                : state.kirimjo.dataTukang[0]?.nama_tukang,
            no_job_order: state.kirimjo.detailJO[0]?.no_job_order,
            tukang_asal: "",
            kode_barang: "",
            nama_barang: "",
            kode_jenis_bahan: "",
            jumlah_akhir: 0,
            berat_akhir: 0,
            berat_batu: 0,
            qty_batu: 0,
            berat_bahan_tambahan: 0,
            qty_batu_lepas: 0,
            berat_batu_lepas: 0,
            qty_batu_rusak: 0,
            berat_batu_rusak: 0,
            qty_batu_sisa_akhir: 0,
            berat_batu_sisa_akhir: 0,
            qty_batu_akhir: 0,
            berat_batu_akhir: 0,
            susut: state.kirimjo.beratSusut,
            jumlah_kirim: state.kirimjo.dataDetailJO[0]?.stock_akhir,
            berat_kirim: state.kirimjo.beratKirim,
            berat_balik: state.kirimjo.beratBalik,
            no_induk_job_order: "",
            bahan_kembali: state.kirimjo.bahan_kembali,
          },
        };
      }
    }
  }
};

let FormKirimJO = ({ visible, onCancel }, prop) => {
  const btnLoading = useSelector(ui.getBtnLoading);
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const dataDivisi = useSelector(KirimBahanAdmin.getAllDivisi);
  const dataTukang = useSelector(KirimJO.getDataTukang);
  const dataInduk = useSelector(KirimJO.getDataNoInduk);
  const dataJO = useSelector(KirimJO.getDataNoJO);
  const isEdit = useSelector(KirimJO.getIsEditJO);
  const dataJenisBahan = useSelector(MasterBahan.getAllMasterBahan);

  useEffect(() => {
    const dataUser = JSON.parse(localStorage.getItem("userInfo"));
    const socket = io.connect(server.replace("/api/v1/", ""));
    socket.on("timbangan-emit", (data) => {
      if (dataUser.user_id === data.user_id) {
        if (window.document.activeElement.name === "berat_kirim") {
          dispatch(change("FormKirimJO", "berat_kirim", data.value));
          dispatch(countBeratKirimJO({ beratKirim: data.value }));
        }
        if (window.document.activeElement.name === "berat_balik") {
          dispatch(change("FormKirimJO", "berat_balik", data.value));
          dispatch(countBeratBalik({ beratBalik: data.value }));
        }
        if (window.document.activeElement.name === "berat_batu_rusak") {
          dispatch(change("FormKirimJO", "berat_batu_rusak", data.value));
          dispatch(countBeratBatuAkhir(data.value, "RUSAK"));
        }
        if (window.document.activeElement.name === "berat_batu_lepas") {
          dispatch(change("FormKirimJO", "berat_batu_lepas", data.value));
          dispatch(countBeratBatuAkhir(data.value, "LEPAS"));
        }
      }
    });
    return () => {
      const socket = io.connect(server.replace("/api/v1/", ""));
      socket.emit("end-socket", () => {});
    };
  }, [dispatch]);

  return (
    <Modal
      visible={visible}
      title="Data Job Order"
      okText="Tambah"
      cancelText="Batal"
      confirmLoading={btnLoading}
      onCancel={onCancel}
      onOk={() => {
        if (isEdit) {
          dispatch(saveEditJobOrder);
        } else {
          dispatch(addLocalKirimJO);
        }
      }}
      width={1200}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[8, 8]}>
          <Col span={6}>
            <Field
              showSearch
              name="divisi_tujuan"
              label={<span style={{ fontSize: "13px" }}>Divisi Tujuan</span>}
              style={{ width: 250 }}
              component={styleAntd.ASelect}
              placeholder="Pilih Divisi Tujuan"
              onBlur={(e) => e.preventDefault()}
              onChange={(e) => dispatch(getTukangByDivisi(e))}
            >
              {dataDivisi.map((item) => {
                if (item.divisi !== "ADMIN BAHAN") {
                  return (
                    <Option value={item.divisi} key={item.divisi}>
                      <span style={{ fontSize: "13px" }}>{item.divisi}</span>
                    </Option>
                  );
                } else {
                  return false;
                }
              })}
            </Field>
          </Col>

          <Col span={6}>
            <Field
              showSearch
              name="tukang_tujuan"
              label={<span style={{ fontSize: "13px" }}>Tukang Tujuan</span>}
              style={{ width: 250 }}
              component={styleAntd.ASelect}
              placeholder="Pilih Tukang Tujuan"
              onBlur={(e) => e.preventDefault()}
              onChange={(e) => dispatch(setTukangTujuan(e))}
            >
              {dataTukang.map((item) => {
                return (
                  <Option value={item.nama_tukang} key={item.kode_tukang}>
                    <span style={{ fontSize: "13px" }}>
                      {item.kode_tukang === item.nama_tukang
                        ? item.nama_tukang
                        : item.nama_tukang + " (" + item.kode_tukang + ")"}
                    </span>
                  </Option>
                );
              })}
            </Field>
          </Col>

          <Col span={6}>
            <Field
              showSearch
              name="no_induk_job_order"
              label={
                <span style={{ fontSize: "13px" }}>No Induk Job Order</span>
              }
              style={{ width: 250 }}
              component={styleAntd.ASelect}
              placeholder="Pilih No Induk Job Order"
              onBlur={(e) => e.preventDefault()}
              onChange={(e) => dispatch(getDataByNoInduk(e))}
              disabled={isEdit}
            >
              {dataInduk.map((item) => {
                return (
                  <Option value={item.no_induk_job_order} key={item._id}>
                    <span style={{ fontSize: "13px" }}>
                      {item.no_induk_job_order}
                    </span>
                  </Option>
                );
              })}
            </Field>
          </Col>
          <Col span={6}>
            <Field
              showSearch
              name="no_job_order"
              label={<span style={{ fontSize: "13px" }}>No Job Order</span>}
              style={{ width: 250 }}
              component={styleAntd.ASelect}
              placeholder="Pilih No Job Order"
              onBlur={(e) => e.preventDefault()}
              onChange={(e) =>
                dispatch(getDataDetailJO({ noJO: e, type: "CHANGE" }))
              }
              disabled={isEdit}
            >
              {dataJO.map((item) => {
                return (
                  <Option value={item.no_job_order} key={item._id}>
                    <span style={{ fontSize: "13px" }}>
                      {item.no_job_order}
                    </span>
                  </Option>
                );
              })}
            </Field>
          </Col>
          {/* <Col span={6}>
            <Field
              name="no_job_order"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>No Job Order</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan No Job Order"
              onBlur={(e) => {
                dispatch(getDataDetailJO({ noJO: e.target.value }));
              }}
            />
          </Col> */}
          <Col span={6}>
            <Field
              name="tukang_asal"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Tukang Asal</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Tukang Asal"
              disabled
            />
          </Col>
          <Col span={6}>
            <Field
              name="kode_barang"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Kode Barang</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Kode Barang"
              disabled
            />
          </Col>
          <Col span={6}>
            <Field
              name="nama_barang"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Nama Barang</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Nama Barang"
              disabled
            />
          </Col>
          <Col span={6}>
            <Field
              name="kode_jenis_bahan"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Kode Jenis Bahan</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Kode Jenis Bahan"
              disabled
            />
          </Col>
          <Col span={6}>
            <Field
              name="jumlah_akhir"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Qty Barang</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Qty Barang"
              disabled
            />
          </Col>
          <Col span={6}>
            <Field
              name="berat_akhir"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Berat Barang</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Barang"
              disabled
            />
          </Col>
          <Col span={6}>
            <Field
              name="qty_batu"
              type="text"
              style={{ width: 250 }}
              label={
                <span style={{ fontSize: "13px" }}>Qty Batu Tambahan</span>
              }
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Batu Tambahan"
              disabled
            />
          </Col>
          <Col span={6}>
            <Field
              name="berat_batu"
              type="text"
              style={{ width: 250 }}
              label={
                <span style={{ fontSize: "13px" }}>Berat Batu Tambahan</span>
              }
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Batu Tambahan"
              disabled
            />
          </Col>
          <Col span={6}>
            <Field
              name="qty_batu_rusak"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Qty Batu Rusak</span>}
              onChange={(e) => {
                dispatch(countQtyBatuAkhir(e.target.value, "RUSAK"));
              }}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <Field
              name="berat_batu_rusak"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Berat Batu Rusak</span>}
              onChange={(e) => {
                dispatch(countBeratBatuAkhir(e.target.value, "RUSAK"));
              }}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <Field
              name="qty_batu_lepas"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Qty Batu Lepas</span>}
              onChange={(e) => {
                dispatch(countQtyBatuAkhir(e.target.value, "LEPAS"));
              }}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <Field
              name="berat_batu_lepas"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Berat Batu Lepas</span>}
              onChange={(e) => {
                dispatch(countBeratBatuAkhir(e.target.value, "LEPAS"));
              }}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="0"
            />
          </Col>
          <Col span={6}>
            <Field
              name="berat_bahan_tambahan"
              type="number"
              style={{ width: 250 }}
              label={
                <span style={{ fontSize: "13px" }}>Berat Bahan Tambahan</span>
              }
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Bahan Tambahan"
              disabled
            />
          </Col>
          <Col span={6}>
            <Field
              name="berat_kirim"
              type="number"
              style={{ width: 250, backgroundColor: "#E5F3FD" }}
              label={<span style={{ fontSize: "13px" }}>Berat Kirim</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="0"
              onChange={(e) => {
                dispatch(countBeratKirimJO({ beratKirim: e.target.value }));
              }}
            />
          </Col>
          <Col span={6}>
            <Field
              name="berat_balik"
              type="number"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Berat Balik</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="0"
              onChange={(e) => {
                dispatch(countBeratBalik({ beratBalik: e.target.value }));
              }}
            />
          </Col>
          <Col span={6}>
            <Field
              showSearch
              name="bahan_kembali"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Bahan Kembali</span>}
              component={styleAntd.ASelect}
              placeholder="Pilih Bahan Kembali"
              onBlur={(e) => e.preventDefault()}
              onChange={(e) => dispatch(setBahanKembaliKirim(e))}
            >
              <Option value="PILIH BAHAN" key="PILIH">
                <span style={{ fontSize: "13px" }}>PILIH BAHAN</span>
              </Option>
              {dataJenisBahan.map((item) => {
                return (
                  <Option value={item.nama_bahan} key={item.nama_bahan}>
                    <span style={{ fontSize: "13px" }}>{item.nama_bahan}</span>
                  </Option>
                );
              })}
            </Field>
          </Col>
          <Col span={6}>
            <Field
              name="jumlah_kirim"
              type="number"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Qty Kirim</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Qty Kirim"
              disabled
              onChange={(e) => {
                dispatch(simpanJumlahKirim({ jumlahKirim: e.target.value }));
              }}
            />
          </Col>
          <Col span={6}>
            <Field
              name="susut"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Susut</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Susut"
              disabled
            />
          </Col>
          <Col className="d-none">
            <Field
              name="qty_batu_sisa_akhir"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Qty Batu Akhir</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Qty Batu Akhir"
              disabled
            />
          </Col>
          <Col className="d-none">
            <Field
              name="berat_batu_sisa_akhir"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Qty Batu Akhir</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Qty Batu Akhir"
              disabled
            />
          </Col>
          <Col span={6}>
            <Field
              name="qty_batu_akhir"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Qty Batu Akhir</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Qty Batu Akhir"
              disabled
            />
          </Col>
          <Col span={6}>
            <Field
              name="berat_batu_akhir"
              type="text"
              style={{ width: 250 }}
              label={<span style={{ fontSize: "13px" }}>Berat Batu Akhir</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Batu Akhir"
              disabled
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

FormKirimJO = reduxForm({
  form: "FormKirimJO",
  enableReinitialize: true,
})(FormKirimJO);
export default connect(maptostate, null)(FormKirimJO);
