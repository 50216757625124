export const GET_ALL_LAPORAN_JOB_ORDER =
  "[laporanjoborder] get all laporan job order";
export const SET_DATA_LAPORAN_JOB_ORDER_SUCCESS =
  "[laporanjoborder] get all laporan job order success";
export const SET_DATA_LAPORAN_JOB_ORDER_FAILED =
  "[laporanjoborder] get all laporan job order failed";

export const getAllLaporanJo = {
  type: GET_ALL_LAPORAN_JOB_ORDER,
};
export const setDataLaporanJoSuccess = ({ feedback }) => ({
  type: SET_DATA_LAPORAN_JOB_ORDER_SUCCESS,
  payload: { data: feedback },
});
export const setDataLaporanJoFailed = ({ error }) => ({
  type: SET_DATA_LAPORAN_JOB_ORDER_FAILED,
  payload: { data: error },
});
