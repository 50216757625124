import {
  setDataDetailPohonSuccess,
  setDataDetailPohonFailed,
  GET_ALL_DETAIL_POHON,
  setNoPohon,
  setDataSusutSuccess,
  setDataBeratTerima,
  SET_SUSUT,
  ADD_TERIMA_COR,
  setBahan,
  SET_SUSUT_BATU_LEPAS,
  SET_SUSUT_BATU_RUSAK,
  setDataBeratBatuLepas,
  setDataBeratBatuRusak,
} from "../actions/terimacor";
import * as sweetalert from "../../infrastructure/shared/sweetalert";

const getDataDetailPohon =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === GET_ALL_DETAIL_POHON) {
      api.TerimaCOR.getDetailPohon(action.payload.data).then((res) => {
        if (res.value !== null) {
          dispatch(setDataDetailPohonSuccess({ feedback: res.value }));
          dispatch(setNoPohon({ feedback: action.payload.data }));
          dispatch(setBahan(res.value[0]?.detail_bahan[0]?.nama_bahan));
        } else {
          sweetalert.default.Failed(
            res.error?.data.message || "Gagal Mengambil Data !"
          );
          dispatch(setDataDetailPohonFailed({ error: res.error }));
        }
      });
    }
  };

const setBeratSusut =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === SET_SUSUT) {
      let berat_murni =
        parseFloat(getState().terimacor.feedback[0]?.berat || 0) +
        parseFloat(getState().terimacor.feedback[0]?.total_berat_batu || 0);
      let berat_terima = action.payload.data || 0;
      let berat_batu_lepas =
        getState().form.FormTerimaCOR.values.berat_batu_lepas;
      let berat_batu_rusak =
        getState().form.FormTerimaCOR.values.berat_batu_rusak;
      let susut = 0;
      susut =
        parseFloat(berat_murni) -
        (parseFloat(berat_batu_lepas || 0) +
          parseFloat(berat_batu_rusak || 0) +
          parseFloat(berat_terima || 0));
      dispatch(setDataSusutSuccess({ feedback: susut.toFixed(3) }));
      dispatch(setDataBeratTerima({ beratTerima: berat_terima }));
    }
    if (action.type === SET_SUSUT_BATU_LEPAS) {
      let berat_murni =
        parseFloat(getState().terimacor.feedback[0]?.berat || 0) +
        parseFloat(getState().terimacor.feedback[0]?.total_berat_batu || 0);
      let berat_batu_lepas = action.payload.data || 0;
      let berat_batu_rusak =
        getState().form.FormTerimaCOR.values.berat_batu_rusak;
      let berat_terima = getState().form.FormTerimaCOR.values.berat_terima;
      let susut = 0;
      susut =
        parseFloat(berat_murni || 0) -
        (parseFloat(berat_batu_lepas || 0) +
          parseFloat(berat_batu_rusak || 0) +
          parseFloat(berat_terima || 0));
      dispatch(setDataSusutSuccess({ feedback: susut.toFixed(3) }));
      dispatch(setDataBeratBatuLepas({ batuLepas: berat_batu_lepas }));
    }
    if (action.type === SET_SUSUT_BATU_RUSAK) {
      let berat_murni =
        parseFloat(getState().terimacor.feedback[0]?.berat || 0) +
        parseFloat(getState().terimacor.feedback[0]?.total_berat_batu || 0);
      let berat_batu_rusak = action.payload.data || 0;
      let berat_batu_lepas =
        getState().form.FormTerimaCOR.values.berat_batu_lepas;
      let berat_terima = getState().form.FormTerimaCOR.values.berat_terima;
      let susut = 0;
      susut =
        parseFloat(berat_murni || 0) -
        (parseFloat(berat_batu_lepas || 0) +
          parseFloat(berat_batu_rusak || 0) +
          parseFloat(berat_terima || 0));
      dispatch(setDataSusutSuccess({ feedback: susut.toFixed(3) }));
      dispatch(setDataBeratBatuRusak({ batuRusak: berat_batu_rusak }));
    }
  };

const addDataTerimaCor =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === ADD_TERIMA_COR) {
      const data = getState().form.FormTerimaCOR.values;
      const dataKirim = {
        no_pohon: data.pohon,
        kode_jenis_bahan: data.real_kode_jenis_bahan,
        berat_awal: parseFloat(data.berat || 0),
        berat_terima: parseFloat(data.berat_terima || 0),
        berat_susut: parseFloat(data.berat_susut || 0),
        nama_bahan: data.kode_jenis_bahan,
        qty_batu_lepas: parseInt(data.jumlah_batu_lepas || 0),
        berat_batu_lepas: parseFloat(data.berat_batu_lepas || 0),
        qty_batu_rusak: parseInt(data.jumlah_batu_rusak || 0),
        berat_batu_rusak: parseFloat(data.berat_batu_rusak || 0),
      };
      api.TerimaCOR.addTerimaCOR(dataKirim).then((res) => {
        if (res.value !== null) {
          sweetalert.default.Success(
            res.value.message || "Berhasil Menerima Casting !"
          );
        } else {
          sweetalert.default.Failed(res.error?.data.message);
        }
      });
    }
  };

const data = [getDataDetailPohon, setBeratSusut, addDataTerimaCor];

export default data;
