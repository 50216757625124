import React from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import laporanpembuatanjenisbahan from "../../../../../application/selectors/laporanpembuatanjenisbahan";

const TableLaporanPembuatanJenisBahanDetail = () => {
  const columns = [
    {
      title: "Nama Bahan",
      dataIndex: "nama_bahan",
      key: "nama_bahan",
      align: "center",
    },
    {
      title: "Berat Pakai",
      dataIndex: "berat",
      key: "berat",
      align: "right",
      render: (item) => {
        return item?.toFixed(3);
      },
    },
    // {
    //   title: "Kadar",
    //   dataIndex: "kadar",
    //   key: "kadar",
    //   align: "right",
    // },
    // {
    //   dataIndex: "karat_24",
    //   title: "24K",
    //   key: "karat_24",
    //   align: "right",
    //   render: (item) => {
    //     return item?.toFixed(3);
    //   },
    // },
  ];

  const dataLaporanPembuatanJenisBahan = useSelector(
    laporanpembuatanjenisbahan.getAllLaporanPembuatanJenisBahan
  );

  return (
    <>
      {dataLaporanPembuatanJenisBahan.map((element) => (
        <>
          <div className="row">
            <div className="col-lg-6">
              <p>No Transaksi : {element.no_transaksi}</p>
            </div>
            <div className="col-lg-6">
              <p>No Pohon : {element.no_pohon}</p>
            </div>
            <div className="col-lg-6">
              <p>Jenis Bahan : {element.nama_jenis_bahan}</p>
            </div>
            <div className="col-lg-12">
              <Table
                dataSource={element.detail_bahan}
                columns={columns}
                scroll={{ x: 1500, y: 1000 }}
                summary={() => (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} align="right">
                        24K
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align="right">
                        {element.karat_24?.toFixed(3)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} align="right">
                        Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align="right">
                        {element.detail_bahan
                          .reduce((a, b) => a + parseFloat(b.berat), 0)
                          ?.toFixed(3)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default TableLaporanPembuatanJenisBahanDetail;
