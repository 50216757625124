import React from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import KirimBatuCOR from "../../../../application/selectors/kirimbatucor";
import { useSelector } from "react-redux";
// import service from "../../../../infrastructure/services/index";

const TableKirimBatuCOR = () => {
  const dataTable = useSelector(KirimBatuCOR.getBatuLocal);

  const columns = [
    {
      title: "Kode Batu",
      dataIndex: "kode_batu",
      key: "kode_batu",
      align: "center",
    },
    {
      title: "Nama Batu",
      dataIndex: "nama_batu",
      key: "nama_batu",
      align: "center",
    },
    {
      title: "Jumlah Batu",
      dataIndex: "jumlah_kirim",
      key: "jumlah_kirim",
      align: "center",
    },
    {
      title: "Berat Batu",
      dataIndex: "berat_kirim",
      key: "berat_kirim",
      align: "center",
    },
  ];
  return (
    <Table
      dataSource={dataTable}
      columns={columns}
      scroll={{ x: 500, y: 1500 }}
    />
  );
};

export default TableKirimBatuCOR;
