import { setLoadingButton } from "../actions/ui";
import * as sweetalert from "../../infrastructure/shared/sweetalert";
import {
  setDataLaporanJoSuccess,
  setDataLaporanJoFailed,
  GET_ALL_LAPORAN_JOB_ORDER,
} from "../actions/laporanjoborder";

const getAllDataJobOrder =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === GET_ALL_LAPORAN_JOB_ORDER) {
      dispatch(setLoadingButton(true));
      dispatch(setDataLaporanJoSuccess({ feedback: [] }));
      const data = getState().form.FormLaporanJobOrder.values;

      if (
        data.divisi === null ||
        data.divisi === undefined ||
        data.kode_tukang === null ||
        data.kode_tukang === undefined
      ) {
        dispatch(setLoadingButton(false));
        sweetalert.default.Failed("Lengkapi Form Terlebih Dahulu !");
      } else {
        writeLocal("laporan_jo", data);

        api.LaporanJobOrder.getAllJobOrder(data).then((res) => {
          dispatch(setLoadingButton(false));
          if (res.value !== null) {
            if (res.value.length === 0) {
              sweetalert.default.Failed("Data Laporan Kosong !");
              dispatch(setDataLaporanJoSuccess({ feedback: [] }));
            } else {
              sweetalert.default.SuccessNoReload("Berhasil Mengambil Data !");
              dispatch(setDataLaporanJoSuccess({ feedback: res.value }));
            }
          } else {
            sweetalert.default.Failed(
              res.error?.data.message || "Terjadi Kesalahan !"
            );
            dispatch(setDataLaporanJoSuccess({ feedback: [] }));
            dispatch(setDataLaporanJoFailed({ error: res.error }));
          }
        });
      }
    }
  };

const data = [getAllDataJobOrder];

export default data;
