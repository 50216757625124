export const GET_TERIMA_TUKANG_POTONG =
  "[terimatukangpotong] get terima tukang potong";
export const SET_DATA_TERIMA_TUKANG_POTONG_SUCCESS =
  "[terimatukangpotong] get terima tukang potong success";
export const SET_DATA_TERIMA_TUKANG_POTONG_FAILED =
  "[terimatukangpotong] get terima tukang potong failed";
export const SET_NO_POHON = "[terimatukangpotong] set no pohon";

export const SET_SUSUT = "[terimatukangpotong] set susut";
export const SET_SUSUT_SUCCESS = "[terimatukangpotong] set susut success";
export const SET_BERAT_TERIMA = "[terimatukangpotong] set berat terima";
export const SET_BERAT_PENTOLAN = "[terimatukangpotong] set berat pentolan";
export const SET_JENIS_BAHAN = "[terimatukangpotong] set jenis bahan";
export const COUNT_PENTOLAN = "[terimatukangpotong] count pentolan";
export const ADD_TERIMA_TUKANG_POTONG =
  "[terimatukangpotong] add terima tukang potong";

export const SET_SUSUT_BATU_LEPAS = "[terimatukangpotong] set susut batu lepas";
export const SET_SUSUT_BATU_RUSAK = "[terimatukangpotong] set susut batu rusak";
export const SET_JUMLAH_BATU_LEPAS = "[terimatukangpotong] set jumlah lepas";
export const SET_BERAT_BATU_LEPAS = "[terimatukangpotong] set berat lepas";
export const SET_JUMLAH_BATU_RUSAK = "[terimatukangpotong] set jumlah rusak";
export const SET_BERAT_BATU_RUSAK = "[terimatukangpotong] set berat rusak";
export const SET_BAHAN = "[terimatukangpotong] set bahan";

export const setBahan = (bahan) => ({
  type: SET_BAHAN,
  payload: { data: bahan },
});
export const getTerimaTukangPotong = ({ noTransaksi }) => ({
  type: GET_TERIMA_TUKANG_POTONG,
  payload: { data: noTransaksi },
});
export const setDataTerimaTukangPotongSuccess = ({ feedback }) => ({
  type: SET_DATA_TERIMA_TUKANG_POTONG_SUCCESS,
  payload: { data: feedback },
});
export const setDataJenisBahan = ({ feedback }) => ({
  type: SET_JENIS_BAHAN,
  payload: { data: feedback },
});
export const setDataTerimaTukangPotongFailed = ({ error }) => ({
  type: SET_DATA_TERIMA_TUKANG_POTONG_FAILED,
  payload: { data: error },
});
export const setNoPohon = ({ feedback }) => ({
  type: SET_NO_POHON,
  payload: { data: feedback },
});

export const countSusut = ({ beratTerima }) => ({
  type: SET_SUSUT,
  payload: { data: beratTerima },
});

export const countSusutPentolan = ({ beratPentolan }) => ({
  type: COUNT_PENTOLAN,
  payload: { data: beratPentolan },
});
export const setDataSusutSuccess = ({ feedback }) => ({
  type: SET_SUSUT_SUCCESS,
  payload: { data: feedback },
});
export const setDataBeratTerima = ({ beratTerima }) => ({
  type: SET_BERAT_TERIMA,
  payload: { data: beratTerima },
});
export const setDataBeratPentolan = ({ beratPentolan }) => ({
  type: SET_BERAT_PENTOLAN,
  payload: { data: beratPentolan },
});

export const addDataTerimaTukangPotong = {
  type: ADD_TERIMA_TUKANG_POTONG,
};

export const countSusutBatuLepas = ({ batuLepas }) => ({
  type: SET_SUSUT_BATU_LEPAS,
  payload: { data: batuLepas },
});
export const countSusutBatuRusak = ({ batuRusak }) => ({
  type: SET_SUSUT_BATU_RUSAK,
  payload: { data: batuRusak },
});

export const setDataBeratBatuLepas = ({ batuLepas }) => ({
  type: SET_BERAT_BATU_LEPAS,
  payload: { data: batuLepas },
});
export const setDataBeratBatuRusak = ({ batuRusak }) => ({
  type: SET_BERAT_BATU_RUSAK,
  payload: { data: batuRusak },
});
export const setDataJumlahBatuLepas = ({ batuLepas }) => ({
  type: SET_JUMLAH_BATU_LEPAS,
  payload: { data: batuLepas },
});
export const setDataJumlahBatuRusak = ({ batuRusak }) => ({
  type: SET_JUMLAH_BATU_RUSAK,
  payload: { data: batuRusak },
});
