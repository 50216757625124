import word from "../../../shared/static";
import get from "../../../axios/get";

const LaporanJobOrder = {
  getAllJobOrder: async (dataKirim) => {
    const response = await get({
      url:
        word.URL_GET_LAPORAN_JOB_ORDER +
        `divisi=${dataKirim.divisi}&kode_tukang=${dataKirim.kode_tukang}`,
    });
    return response;
  },
};

export default LaporanJobOrder;
