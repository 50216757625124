import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Form, Button, Row, Col, Select } from "antd";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import styleAntd from "../../../../../infrastructure/shared/styleAntd";
import ui from "../../../../../application/selectors/ui";
import "antd/dist/antd.css";
import { getAllLaporanSetorAbuCor } from "../../../../../application/actions/laporansetorabucor";
import DataBahan from "../../../../../application/selectors/masterbahan";

const dateFormat = "DD/MM/YYYY";
const today = new Date();
const { Option } = Select;

const maptostate = (state) => {
  if (state.form.FormLaporanSetorAbuCOR?.values !== undefined) {
    return {
      initialValues: {
        date: state.form.FormLaporanSetorAbuCOR?.values.date,
        bahan_kembali: "SEMUA",
      },
    };
  } else {
    return {
      initialValues: {
        date: [moment(today, dateFormat), moment(today, dateFormat)],
        bahan_kembali: "SEMUA",
      },
    };
  }
};

let FormLaporanSetorAbuCOR = (prop) => {
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const btnLoading = useSelector(ui.getBtnLoading);
  const dataBahan = useSelector(DataBahan.getAllMasterBahan);
  return (
    <Form layout="vertical">
      <Row>
        <Col span={4} style={{ marginRight: 20 }}>
          <Field
            name="date"
            type="date"
            label={<span style={{ fontSize: "13px" }}>Tanggal</span>}
            component={styleAntd.ARangePick}
            className="form-item-group"
            onBlur={(e) => e.preventDefault()}
          />
        </Col>
        <Col span={4}>
          <Field
            showSearch
            name="bahan_kembali"
            label={<span style={{ fontSize: "13px" }}>Bahan Kembali</span>}
            component={styleAntd.ASelect}
            placeholder="Bahan Kembali"
            onBlur={(e) => e.preventDefault()}
          >
            <Option value="SEMUA" key="SEMUA">
              <span style={{ fontSize: "13px" }}>SEMUA</span>
            </Option>
            {dataBahan.map((item) => {
              return (
                <Option value={item.nama_bahan} key={item._id}>
                  <span style={{ fontSize: "13px" }}>{item.nama_bahan}</span>
                </Option>
              );
            })}
          </Field>
        </Col>
        <Col offset={1}>
          <Button
            type="primary"
            htmltype="button"
            loading={btnLoading}
            onClick={() => prop.dispatch(getAllLaporanSetorAbuCor)}
            style={{ marginTop: 29 }}
          >
            Lihat Laporan
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

FormLaporanSetorAbuCOR = reduxForm({
  form: "FormLaporanSetorAbuCOR",
  enableReinitialize: true,
})(FormLaporanSetorAbuCOR);
export default connect(maptostate, null)(FormLaporanSetorAbuCOR);
