import React from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import laporangabungjo from "../../../../../application/selectors/laporangabungjo";

const TableLaporanGabungJO = () => {
  const columns = [
    {
      title: "No Transaksi",
      dataIndex: "no_transaksi",
      key: "no_transaksi",
      align: "left",
    },
    {
      title: "No Pohon",
      dataIndex: "no_pohon",
      key: "no_pohon",
      align: "left",
    },
    {
      title: "Jenis Bahan",
      dataIndex: "jenis_bahan",
      key: "jenis_bahan",
      align: "left",
    },
  ];

  const dataLap = useSelector(laporangabungjo.getAllLaporanGabungJO);

  return (
    <Table
      dataSource={dataLap}
      columns={columns}
      scroll={{ x: 1500, y: 1000 }}
      // summary={() => (
      //   <Table.Summary fixed>
      //     <Table.Summary.Row>
      //       <Table.Summary.Cell index={0} colSpan={4} align="right">
      //         Total
      //       </Table.Summary.Cell>
      //       <Table.Summary.Cell index={1} align="right">
      //         {dataLap.reduce((a, b) => a + parseFloat(b.stock), 0)}
      //       </Table.Summary.Cell>
      //       <Table.Summary.Cell index={2} align="right">
      //         {dataLap.reduce((a, b) => a + parseFloat(b.berat), 0).toFixed(3)}
      //       </Table.Summary.Cell>
      //     </Table.Summary.Row>
      //   </Table.Summary>
      // )}
    />
  );
};

export default TableLaporanGabungJO;
