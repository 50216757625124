export const GET_ALL_LAPORAN_GABUNG_JO =
  "[laporangabungjo] get all laporan gabung jo";
export const SET_DATA_LAPORAN_GABUNG_JO_SUCCESS =
  "[laporangabungjo] get all laporan gabung jo success";
export const SET_DATA_LAPORAN_GABUNG_JO_FAILED =
  "[laporangabungjo] get all laporan gabung jo failed";

export const getAllLaporanGabungJO = {
  type: GET_ALL_LAPORAN_GABUNG_JO,
};
export const setDataLaporanGabungJOSuccess = ({ feedback }) => ({
  type: SET_DATA_LAPORAN_GABUNG_JO_SUCCESS,
  payload: { data: feedback },
});
export const setDataLaporanGabungJOFailed = ({ error }) => ({
  type: SET_DATA_LAPORAN_GABUNG_JO_FAILED,
  payload: { data: error },
});
