import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Form, Row, Col, Select, Modal } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, change, reduxForm } from "redux-form";
import styleAntd from "../../../../infrastructure/shared/styleAntd";
import ui from "../../../../application/selectors/ui";
import TambahAmbilBatu from "../../../../application/selectors/tambahambilbatu";
import {
  getDetailBatuByKode,
  simpanBatuLocal,
} from "../../../../application/actions/kirimbatucor";
import { server } from "../../helpers/library";
import { io } from "socket.io-client";

const { Option } = Select;

const maptostate = (state) => {
  return {
    initialValues: {
      kode_batu: state.kirimbatupusat.feedback[0]?.kode_batu,
    },
  };
};

let FormDetailBatuCOR = ({ visible, onCreate, onCancel }, prop) => {
  const btnLoading = useSelector(ui.getBtnLoading);
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const dataBatu = useSelector(TambahAmbilBatu.getAllTambahAmbilBatu);

  useEffect(() => {
    const dataUser = JSON.parse(localStorage.getItem("userInfo"));
    const socket = io.connect(server.replace("/api/v1/", ""));
    socket.on("timbangan-emit", (data) => {
      if (dataUser.user_id === data.user_id) {
        dispatch(change("FormDetailBatuCOR", "berat_kirim", data.value));
      }
    });
    return () => {
      const socket = io.connect(server.replace("/api/v1/", ""));
      socket.emit("end-socket", () => {});
    };
  }, [dispatch]);

  return (
    <Modal
      visible={visible}
      title="Detail Batu COR"
      okText="Tambah"
      cancelText="Batal"
      confirmLoading={btnLoading}
      onCancel={onCancel}
      onOk={() => dispatch(simpanBatuLocal)}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Field
              showSearch
              name="kode_batu"
              label={<span style={{ fontSize: "13px" }}>Kode Batu</span>}
              component={styleAntd.ASelect}
              placeholder="Pilih Kode Batu"
              onBlur={(e) => e.preventDefault()}
              onChange={(e) => dispatch(getDetailBatuByKode({ kodeBatu: e }))}
            >
              {dataBatu.map((item) => {
                return (
                  <Option value={item.kode_batu} key={item.kode_batu}>
                    <span style={{ fontSize: "13px" }}>{item.kode_batu}</span>
                  </Option>
                );
              })}
            </Field>
          </Col>
          <Col className="d-none">
            <Field
              name="nama_batu"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Nama Batu</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Nama Batu"
              disabled
            />
          </Col>
          <Col span={12}>
            <Field
              name="jumlah_batu"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Jumlah Batu</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Jumlah Batu"
              disabled
            />
          </Col>
          <Col span={12}>
            <Field
              name="berat_batu"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Berat Batu</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Batu"
              disabled
            />
          </Col>
          <Col span={12}>
            <Field
              name="jumlah_kirim"
              type="text"
              label={
                <span style={{ fontSize: "13px" }}>Jumlah Batu Kirim</span>
              }
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Jumlah Batu Kirim"
              // onBlur={(e) => {
              //   dispatch(countBeratBatuKirim({ jumlah: e.target.value }));
              // }}
            />
          </Col>
          <Col span={12}>
            <Field
              name="berat_kirim"
              type="text"
              label={<span style={{ fontSize: "13px" }}>Berat Batu Kirim</span>}
              component={styleAntd.AInput}
              className="form-item-group"
              placeholder="Masukkan Berat Batu Kirim"
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

FormDetailBatuCOR = reduxForm({
  form: "FormDetailBatuCOR",
  enableReinitialize: true,
})(FormDetailBatuCOR);
export default connect(maptostate, null)(FormDetailBatuCOR);
