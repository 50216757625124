// Type your data here, it can be string, or any type of data, just write your variable
import { SET_DATA_BATU } from "../actions/kirimbatucor";

const initialState = {
  feedback: [],
};

const kirimbatucor = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_BATU:
      return {
        ...state,
        feedback: action.payload.data,
      };
    default:
      return state;
  }
};

export default kirimbatucor;
