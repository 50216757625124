import get from "../../../axios/get";
import post from "../../../axios/post";
import word from "../../../shared/static";

const KirimJO = {
  getDataBatuTambahan: async (nojo, divisi) => {
    const response = await get({
      url: `${word.URL_GET_DATA_BATU_TAMBAHAN}?no_job_order=${nojo}&divisi=${divisi}`,
    });
    return response;
  },
  getDataBahanTambahan: async (nojo, divisi) => {
    const response = await get({
      url: `${word.URL_GET_DATA_BAHAN_TAMBAHAN}?no_job_order=${nojo}&divisi=${divisi}`,
    });
    return response;
  },
  getAllJO: async (divisi) => {
    const response = await get({
      url:
        word.URL_GET_JO_ALL +
        "?asal_divisi=" +
        divisi +
        "&tujuan_divisi=" +
        divisi,
    });
    return response;
  },
  getDetailJO: async (noJO, divisi) => {
    const response = await get({
      url: `${word.URL_GET_DETAIL_TAMBAH_JO}?no_job_order=${noJO}&divisi=${divisi}`,
    });
    return response;
  },
  getTukangByDivisi: async (data) => {
    const response = await get({ url: word.URL_GET_TUKANG_DIVISI + data });
    return response;
  },
  getNoIndulJO: async (divisi) => {
    const response = await get({
      url:
        word.URL_GET_NO_INDUK_JO +
        "?asal_divisi=" +
        divisi +
        "&tujuan_divisi=" +
        divisi,
    });
    return response;
  },
  getJobOrderDetail: async (data) => {
    const response = await get({
      url: word.URL_GET_JO_BY_NO_INDUK + `/${data.no_induk}/${data.divisi}`,
    });
    return response;
  },
  addKirimJOCart: async (dataKirim) => {
    const response = await post.AxiosPost({
      url: word.URL_ADD_KIRIM_JOB_ORDER_CART,
      data: dataKirim,
    });
    return response;
  },
  addKirimJOCheckOut: async (dataKirim) => {
    const response = await post.AxiosPost({
      url: word.URL_ADD_KIRIM_JOB_ORDER_CHECKOUT,
      data: dataKirim,
    });
    return response;
  },
};

export default KirimJO;
