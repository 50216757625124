import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../../components/panel/panel.jsx";
import { Card, Divider } from "antd";
import { pageLoadedLogin } from "../../../../application/actions/ui";
import FormLaporanGabungJO from "../../../components/laporan/laporan-admin-pusat/laporan-gabung-jo/form-laporan-gabung-jo";
import TableLaporanGabungJO from "../../../components/laporan/laporan-admin-pusat/laporan-gabung-jo/table-laporan-gabung-jo";
import BtnPrint from "../../../components/laporan/laporan-admin-pusat/laporan-gabung-jo/btn-print-excel-pdf";
import GabungJO from "../../../../application/selectors/laporangabungjo.jsx";
import { getAllMasterJenisBahan } from "../../../../application/actions/masterjenisbahan.jsx";

const LaporanGabungJO = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(pageLoadedLogin);
    dispatch(getAllMasterJenisBahan);
    document.title = "Laporan Gabung JO";
  }, [dispatch]);
  const dataLap = useSelector(GabungJO.getAllLaporanGabungJO);

  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/laporan/admin-pusat/gabung-jo">Laporan Admin Pusat</Link>
        </li>
        <li className="breadcrumb-item active">Gabung JO</li>
      </ol>
      <h1 className="page-header">
        Laporan Admin Pusat <small>Gabung JO</small>
      </h1>
      <Panel>
        <PanelHeader>Gabung JO</PanelHeader>
        <PanelBody>
          <Card bordered={false}>
            <div className="row">
              <div className="col-12">
                <FormLaporanGabungJO />
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: 10,
                display: dataLap.length === 0 ? "none" : "",
              }}
            >
              <div className="col-12">
                <Divider orientation="left" style={{ fontSize: "14px" }}>
                  Tabel Laporan
                </Divider>
              </div>
              <div className="col-12">
                <TableLaporanGabungJO />
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: 10,
                display: dataLap.length === 0 ? "none" : "",
              }}
            >
              <div className="col-12">
                <BtnPrint />
              </div>
            </div>
          </Card>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default LaporanGabungJO;
