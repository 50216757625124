import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

class ExcelReportDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let totalBerat = 0;
    let totalKarat24 = 0;
    this.props.dataExel.forEach((element) => {
      totalKarat24 += parseFloat(element.karat_24);
      element.detail_bahan.forEach((item) => {
        totalBerat += parseFloat(item.berat);
      });
    });
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="ant-btn ant-btn-primary ant-btn-block ant-btn-success"
          table="table-to-xls-detail"
          filename="LAPORAN PEMBUATAN JENIS BAHAN DETAIL"
          sheet="LAPORAN PEMBUATAN JENIS BAHAN DETAIL"
          buttonText="Export Excel"
        />
        <table id="table-to-xls-detail" style={{ display: "none" }}>
          <thead>
            <tr>
              <td
                style={{
                  color: "#000",
                  textAlign: "center",
                }}
                colSpan="3"
              >
                LAPORAN PEMBUATAN JENIS BAHAN DETAIL
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#000",
                  textAlign: "center",
                }}
                colSpan="3"
              >
                TANGGAL{" "}
                {this.props.dataHead?.tgl_awal +
                  " s/d " +
                  this.props.dataHead?.tgl_akhir}
              </td>
            </tr>
            <tr colSpan="3"></tr>
          </thead>
          <tbody>
            {this.props.dataExel.map((element) => {
              return (
                <>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        backgroundColor: "#E8E5E5",
                        textAlign: "left",
                      }}
                    >
                      Tanggal : {element.tanggal}
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        textAlign: "left",
                      }}
                    >
                      No Pohon : {element.no_pohon}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        backgroundColor: "#E8E5E5",
                        textAlign: "left",
                      }}
                    >
                      No Transaksi : {element.no_transaksi}
                    </td>
                    <td
                      style={{
                        backgroundColor: "#E8E5E5",
                        textAlign: "left",
                      }}
                    >
                      Jenis Bahan : {element.nama_jenis_bahan}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        backgroundColor: "#bbbbbb",
                        textAlign: "center",
                      }}
                    >
                      No
                    </td>
                    <td
                      style={{
                        backgroundColor: "#bbbbbb",
                        textAlign: "center",
                      }}
                    >
                      Nama Bahan
                    </td>
                    <td
                      style={{
                        backgroundColor: "#bbbbbb",
                        textAlign: "center",
                      }}
                    >
                      Berat
                    </td>
                    {/* <td
                      style={{
                        backgroundColor: "#bbbbbb",
                        textAlign: "center",
                      }}
                    >
                      Kadar
                    </td>
                    <td
                      style={{
                        backgroundColor: "#bbbbbb",
                        textAlign: "center",
                      }}
                    >
                      24K
                    </td> */}
                  </tr>
                  {element.detail_bahan.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.nama_bahan}</td>
                        <td style={{ textAlign: "right" }}>
                          {item.berat?.toFixed(3)}
                        </td>
                        {/* <td style={{ textAlign: "right" }}>{item.kadar}</td>
                        <td style={{ textAlign: "right" }}>
                          {item.karat_24?.toFixed(3)}
                        </td> */}
                      </tr>
                    );
                  })}
                  <tr>
                    <td
                      colSpan={2}
                      style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}
                    >
                      24K :
                    </td>
                    <td
                      style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}
                    >
                      {element.karat_24?.toFixed(3)}
                    </td>
                    {/* <td
                      style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}
                    ></td> */}
                    {/* <td
                      style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}
                    >
                      {element.detail_bahan
                        .reduce((a, b) => a + parseFloat(b.karat_24), 0)
                        ?.toFixed(3)}
                    </td> */}
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}
                    >
                      Sub Total :
                    </td>
                    <td
                      style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}
                    >
                      {element.detail_bahan
                        .reduce((a, b) => a + parseFloat(b.berat), 0)
                        ?.toFixed(3)}
                    </td>
                    {/* <td
                      style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}
                    ></td> */}
                    {/* <td
                      style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}
                    >
                      {element.detail_bahan
                        .reduce((a, b) => a + parseFloat(b.karat_24), 0)
                        ?.toFixed(3)}
                    </td> */}
                  </tr>
                </>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={2}
                style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}
              >
                Total 24K:
              </td>
              <td style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}>
                {totalKarat24?.toFixed(3)}
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}
              >
                Grand Total :
              </td>
              <td style={{ textAlign: "right", backgroundColor: "#bbbbbb" }}>
                {totalBerat?.toFixed(3)}
              </td>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default ExcelReportDetail;
