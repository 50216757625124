import get from "../../../axios/get";
import word from "../../../shared/static";

const LaporanGabungJO = {
  getAllLaporanGabungJO: async (dataKirim) => {
    const response = await get({
      url:
        word.URL_GET_LAPORAN_GABUNG_JO +
        `start_date=${dataKirim.tgl_awal}&end_date=${dataKirim.tgl_akhir}&kode_jenis_bahan=${dataKirim.kode_jenis_bahan}`,
    });
    return response;
  },
};

export default LaporanGabungJO;
