const getAllLaporanSetorAbuCOR = (state) => state.laporansetorabucor.feedback;
const getErrorLaporanSetorAbuCOR = (state) => state.laporansetorabucor.error;
const getIsEditLaporanSetorAbuCOR = (state) => state.laporansetorabucor.isEdit;

const data = {
  getAllLaporanSetorAbuCOR,
  getErrorLaporanSetorAbuCOR,
  getIsEditLaporanSetorAbuCOR,
};

export default data;
