import post from "../../../axios/post";
import word from "../../../shared/static";

const KirimBatuCOR = {
  addKirimBatuCOR: async (dataKirim) => {
    const response = await post.AxiosPost({
      url: word.URL_ADD_KIRIM_BATU_COR,
      data: dataKirim,
    });
    return response;
  },
};

export default KirimBatuCOR;
