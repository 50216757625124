import React from "react";
import { connect, useSelector } from "react-redux";
import { Form, Button, Row, Col, Select } from "antd";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import styleAntd from "../../../../../infrastructure/shared/styleAntd";
import ui from "../../../../../application/selectors/ui";
import "antd/dist/antd.css";
import { getAllLaporanSetorAbuTukang } from "../../../../../application/actions/laporansetorabutukang";
import divisimaster from "../../../../../application/selectors/kirimbahanadmin";
import DataBahan from "../../../../../application/selectors/masterbahan";

const dateFormat = "DD/MM/YYYY";
const today = new Date();
const { Option } = Select;

const maptostate = (state) => {
  if (state.form.FormLaporanSetorAbuTukang?.values !== undefined) {
    return {
      initialValues: {
        date: state.form.FormLaporanSetorAbuTukang?.values.date,
        divisi: state.kirimbahanadmin.feedback[0]?.divisi,
        bahan_kembali: "SEMUA",
      },
    };
  } else {
    return {
      initialValues: {
        date: [moment(today, dateFormat), moment(today, dateFormat)],
        divisi: state.kirimbahanadmin.feedback[0]?.divisi,
        bahan_kembali: "SEMUA",
      },
    };
  }
};

let FormLaporanSetorAbuTukang = (prop) => {
  const btnLoading = useSelector(ui.getBtnLoading);
  const dataDivisi = useSelector(divisimaster.getAllDivisi);
  const dataBahan = useSelector(DataBahan.getAllMasterBahan);
  return (
    <Form layout="vertical">
      <Row>
        <Col span={4} style={{ marginRight: 20 }}>
          <Field
            name="date"
            type="date"
            label={<span style={{ fontSize: "13px" }}>Tanggal</span>}
            component={styleAntd.ARangePick}
            className="form-item-group"
            onBlur={(e) => e.preventDefault()}
          />
        </Col>
        <Col span={4} style={{ marginRight: 20 }}>
          <Field
            showSearch
            name="divisi"
            label={<span style={{ fontSize: "13px" }}>Divisi</span>}
            component={styleAntd.ASelect}
            placeholder="Pilih Divisi"
            onBlur={(e) => e.preventDefault()}
          >
            {dataDivisi.map((item) => {
              return (
                <Option value={item.divisi} key={item.divisi}>
                  <span style={{ fontSize: "13px" }}>{item.divisi}</span>
                </Option>
              );
            })}
          </Field>
        </Col>
        <Col span={4} style={{ marginRight: 20 }}>
          <Field
            showSearch
            name="bahan_kembali"
            label={<span style={{ fontSize: "13px" }}>Bahan Kembali</span>}
            component={styleAntd.ASelect}
            placeholder="Bahan Kembali"
            onBlur={(e) => e.preventDefault()}
          >
            <Option value="SEMUA" key="SEMUA">
              <span style={{ fontSize: "13px" }}>SEMUA</span>
            </Option>
            {dataBahan.map((item) => {
              return (
                <Option value={item.nama_bahan} key={item._id}>
                  <span style={{ fontSize: "13px" }}>{item.nama_bahan}</span>
                </Option>
              );
            })}
          </Field>
        </Col>
        <Col offset={1}>
          <Button
            type="primary"
            htmltype="button"
            loading={btnLoading}
            onClick={() => prop.dispatch(getAllLaporanSetorAbuTukang)}
            style={{ marginTop: 29 }}
          >
            Lihat Laporan
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

FormLaporanSetorAbuTukang = reduxForm({
  form: "FormLaporanSetorAbuTukang",
  enableReinitialize: true,
})(FormLaporanSetorAbuTukang);
export default connect(maptostate, null)(FormLaporanSetorAbuTukang);
