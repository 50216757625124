import React from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import { useSelector } from "react-redux";
import laporanjoborder from "../../../../../application/selectors/laporanjoborder";

const TableJobOrder = () => {
  const columns = [
    {
      title: "No Job Order",
      dataIndex: "no_job_order",
      key: "no_job_order",
      align: "center",
    },
    {
      title: "Tukang",
      dataIndex: "kode_tukang",
      key: "kode_tukang",
      align: "center",
    },
    {
      title: "Kode Barang",
      dataIndex: "kode_barang",
      key: "kode_barang",
      align: "center",
    },
    {
      title: "Jenis Bahan",
      dataIndex: "kode_jenis_bahan",
      key: "kode_jenis_bahan",
      align: "center",
    },
    {
      title: "Qty Barang",
      dataIndex: "qty_barang",
      key: "qty_barang",
      align: "right",
    },
    {
      title: "Berat Barang",
      dataIndex: "berat_barang",
      key: "berat_barang",
      align: "right",
    },
  ];

  const dataLap = useSelector(laporanjoborder.getAllLaporanJobOrder);

  return (
    <Table
      dataSource={dataLap}
      columns={columns}
      scroll={{ x: 1500, y: 1000 }}
      summary={() => (
        <Table.Summary fixed>
          <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={4} align="right">
              Total
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} align="right">
              {dataLap.reduce((a, b) => a + parseFloat(b.qty_barang), 0)}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2} align="right">
              {dataLap
                .reduce((a, b) => a + parseFloat(b.berat_barang), 0)
                .toFixed(3)}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )}
    />
  );
};

export default TableJobOrder;
