import {
  setDataSaldoBahanStockSuccess,
  setDataSaldoBahanStockFailed,
  GET_ALL_SALDO_BAHAN_STOCK,
  ADD_DETAIL_JENIS_BAHAN,
  addDataDetailJenisBahanSuccess,
  addDataDetailBahanSuccess,
  ADD_DETAIL_BAHAN,
  ADD_PEMBUATAN_JENIS_BAHAN,
  DELETE_DETAIL_JENIS_BAHAN,
  DELETE_BAHAN,
  RESET_PEMBUATAN_JENIS_BAHAN,
  setDetailBillOfMaterials,
  GET_ALL_NO_KIRIM_BATU_COR,
  setAllNOKirimBatuCOR,
  GET_DETAIL_NO_KIRIM,
  SAVE_DETAIL_KIRIM_BATU_LOCAL,
} from "../actions/pembuatanjenisbahan";
import { setLoadingButton } from "../actions/ui";
import * as sweetalert from "../../infrastructure/shared/sweetalert";
import Swal from "sweetalert2";
import { change } from "redux-form";

const saldoBahanGetAll =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === GET_ALL_SALDO_BAHAN_STOCK) {
      api.PembuatanJenisBahan.getAllSaldoBahanStock().then((res) => {
        if (res.value !== null) {
          dispatch(setDataSaldoBahanStockSuccess({ feedback: res.value }));
        } else {
          dispatch(setDataSaldoBahanStockFailed({ error: res.error }));
        }
      });
    }
    if (action.type === GET_ALL_NO_KIRIM_BATU_COR) {
      api.PembuatanJenisBahan.getAllNoKirimBatuCOR().then((res) => {
        if (res.value !== null) {
          dispatch(setAllNOKirimBatuCOR({ feedback: res.value }));
        } else {
          dispatch(setAllNOKirimBatuCOR({ feedback: [] }));
        }
      });
    }
    if (action.type === GET_DETAIL_NO_KIRIM) {
      api.PembuatanJenisBahan.getNoKirimBatuCOR(action.payload.data).then(
        (res) => {
          if (res.value !== null) {
            const totalJumlah = res.value.detail_batu.reduce(
              (a, b) => a + parseInt(b.qty),
              0
            );
            dispatch(change("FormTambahBatuKirimCOR", "jumlah", totalJumlah));
            const totalBerat = res.value.detail_batu.reduce(
              (a, b) => a + parseFloat(b.berat),
              0
            );
            dispatch(change("FormTambahBatuKirimCOR", "berat", totalBerat));
            dispatch(
              change(
                "FormTambahBatuKirimCOR",
                "noKirim",
                res.value.no_kirim_batu
              )
            );
          } else {
            dispatch(change("FormTambahBatuKirimCOR", "jumlah", 0));
            dispatch(change("FormTambahBatuKirimCOR", "berat", 0));
          }
        }
      );
    }
  };

const addDetailJenisBahan =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === ADD_DETAIL_JENIS_BAHAN) {
      const data = getState().form.FormPembuatanJenisBahanDetail?.values;
      let datalocal = [];
      if (
        data.berat_dibutuhkan === undefined ||
        data.berat_susut === undefined ||
        data.no_pohon === undefined ||
        data.kode_jenis_bahan === undefined
      ) {
        sweetalert.default.Failed("Lengkapi Form Terlebih Dahulu !");
      } else {
        if (
          parseFloat(data.berat_dibutuhkan) < 0 ||
          parseFloat(data.berat_susut) < 0
        ) {
          sweetalert.default.Failed("Berat Tidak Boleh Kurang Dari 0 !");
        } else {
          const dataDetail = getLocal("data_detail_jenis_bahan");
          api.PembuatanJenisBahan.getBillOfMaterials(data).then((res) => {
            if (res.value !== null) {
              dispatch(setDetailBillOfMaterials({ feedback: res.value }));
              writeLocal("data_detail_bahan", res.value);
              if (dataDetail === null || dataDetail.length === 0) {
                dispatch(addDataDetailJenisBahanSuccess({ feedback: data }));
                datalocal.push(data);
                writeLocal("data_detail_jenis_bahan", datalocal);
                sweetalert.default.Success("Berhasil Menambahkan Data !");
              } else {
                Swal.fire({
                  title: "Add Data",
                  text: "Apakah Anda Yakin Akan Mengganti Data Sebelumnya ?",
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ya",
                }).then((result) => {
                  if (result.isConfirmed) {
                    sweetalert.default.Success("Berhasil Menambahkan Data !");
                    dispatch(
                      addDataDetailJenisBahanSuccess({ feedback: data })
                    );
                    datalocal.push(data);
                    writeLocal("data_detail_jenis_bahan", datalocal);
                  }
                });
              }
            } else {
              dispatch(setDetailBillOfMaterials({ feedback: [] }));
              if (dataDetail === null || dataDetail.length === 0) {
                dispatch(addDataDetailJenisBahanSuccess({ feedback: data }));
                datalocal.push(data);
                writeLocal("data_detail_jenis_bahan", datalocal);
                sweetalert.default.Success("Berhasil Menambahkan Data !");
              } else {
                Swal.fire({
                  title: "Add Data",
                  text: "Apakah Anda Yakin Akan Mengganti Data Sebelumnya ?",
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Ya",
                }).then((result) => {
                  if (result.isConfirmed) {
                    sweetalert.default.Success("Berhasil Menambahkan Data !");
                    dispatch(
                      addDataDetailJenisBahanSuccess({ feedback: data })
                    );
                    datalocal.push(data);
                    writeLocal("data_detail_jenis_bahan", datalocal);
                  }
                });
              }
            }
          });
        }
      }
    }
  };

const addDetailBahan =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === ADD_DETAIL_BAHAN) {
      if (
        getLocal("data_detail_bahan") === undefined ||
        getLocal("data_detail_bahan") === null
      ) {
        const data = getState().form.FormTambahBahan?.values;
        let datalocal = [];
        if (data.berat === undefined) {
          sweetalert.default.Failed("Lengkapi Form Terlebih Dahulu !");
        } else if (!getLocal("data_detail_jenis_bahan")) {
          sweetalert.default.Failed(
            "Tambahkan Detail Jenis Bahan Terlebih Dahulu !"
          );
        } else {
          if (parseFloat(data.berat) < 0) {
            sweetalert.default.Failed("Berat Tidak Boleh Kurang Dari 0 !");
          } else {
            sweetalert.default.Success("Berhasil Menambahkan Data !");
            dispatch(addDataDetailBahanSuccess({ feedback: data }));
            datalocal.push(data);
            writeLocal("data_detail_bahan", datalocal);
          }
        }
      } else {
        const data = getState().form.FormTambahBahan?.values;
        let datalocal = getLocal("data_detail_bahan");
        const cekData = datalocal.find((val) => {
          return val.kode_bahan === data.kode_bahan;
        });
        if (data.berat === undefined) {
          sweetalert.default.Failed("Lengkapi Form Terlebih Dahulu !");
        } else if (!getLocal("data_detail_jenis_bahan")) {
          sweetalert.default.Failed(
            "Tambahkan Detail Jenis Bahan Terlebih Dahulu !"
          );
        } else if (cekData) {
          sweetalert.default.Failed(
            `Bahan ${data.kode_bahan} Sudah Ada Pada Tabel !`
          );
        } else {
          sweetalert.default.Failed(
            `Bahan ${data.kode_bahan} Sudah Ada Pada Tabel !`
          );
          if (parseFloat(data.berat) < 0) {
            sweetalert.default.Failed("Berat Tidak Boleh Kurang Dari 0 !");
          } else {
            sweetalert.default.Success("Berhasil Menambahkan Data !");
            dispatch(addDataDetailBahanSuccess({ feedback: data }));
            datalocal.push(data);
            writeLocal("data_detail_bahan", datalocal);
          }
        }
      }
    }
  };

const addDataPembuatanJenisBahan =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === ADD_PEMBUATAN_JENIS_BAHAN) {
      dispatch(setLoadingButton(true));
      const dataDetailJenisBahan = getLocal("data_detail_jenis_bahan");
      const dataDetailBahan = getLocal("data_detail_bahan");
      const dataDetailBatu = getLocal("temp_detail_kirim_batu_cor");
      let dataDetailNewArr = [];
      dataDetailBahan.forEach((element) => {
        const row = {
          nama_bahan: element.kode_bahan,
          berat_bahan: parseFloat(element.berat),
        };
        dataDetailNewArr.push(row);
      });
      const onSendData = {
        berat: parseFloat(dataDetailJenisBahan[0].berat_dibutuhkan),
        berat_susut: parseFloat(dataDetailJenisBahan[0].berat_susut),
        detail_bahan: dataDetailNewArr,
        kode_jenis_bahan: dataDetailJenisBahan[0].kode_jenis_bahan,
        no_pohon: dataDetailJenisBahan[0].no_pohon,
        no_kirim_batu: dataDetailBatu?.noKirim || "-",
      };
      api.PembuatanJenisBahan.addPembuatanJenisBahan(onSendData).then((res) => {
        if (res.value !== null) {
          dispatch(setLoadingButton(false));
          sweetalert.default.Success("Berhasil Membuat Bahan !");
          localStorage.removeItem("data_detail_jenis_bahan");
          localStorage.removeItem("data_detail_bahan");
          localStorage.removeItem("temp_detail_kirim_batu_cor");
        } else {
          dispatch(setLoadingButton(false));
          sweetalert.default.Failed(
            res.error?.data.message || "Gagal Membuat Bahan !"
          );
        }
      });
    }
    if (action.type === SAVE_DETAIL_KIRIM_BATU_LOCAL) {
      const datalocal = getLocal("temp_detail_kirim_batu_cor");
      const data = getState().form.FormTambahBatuKirimCOR.values;
      if (datalocal === null || datalocal === undefined) {
        writeLocal("temp_detail_kirim_batu_cor", data);
        sweetalert.default.Success("Berhasil Menambahkan Data");
      } else {
        sweetalert.default.Info("Hanya Bisa 1 No Kirim");
      }
    }
  };

const deleteDetailJenisBahan =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === DELETE_DETAIL_JENIS_BAHAN) {
      // console.log(action.payload.data);
      const id = action.payload.data;
      const data = getLocal("data_detail_jenis_bahan");
      const dataFill = data.filter((val) => val.no_pohon !== id);
      writeLocal("data_detail_jenis_bahan", dataFill);
      sweetalert.default.Success("Berhasil Menghapus Data !");
    }
  };

const deleteBahan =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === DELETE_BAHAN) {
      const id = action.payload.data;
      const data = getLocal("data_detail_bahan");
      const dataFill = data.filter((val) => val.kode_bahan !== id);
      writeLocal("data_detail_bahan", dataFill);
      sweetalert.default.Success("Berhasil Menghapus Data !");
    }
  };

const resetPembuatanJenisBahan =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === RESET_PEMBUATAN_JENIS_BAHAN) {
      localStorage.removeItem("data_detail_bahan");
      localStorage.removeItem("data_detail_jenis_bahan");
      sweetalert.default.Success("Berhasil Menghapus Data !");
    }
  };

const data = [
  saldoBahanGetAll,
  addDetailJenisBahan,
  addDetailBahan,
  addDataPembuatanJenisBahan,
  deleteDetailJenisBahan,
  deleteBahan,
  resetPembuatanJenisBahan,
];

export default data;
