import React, { Component } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

class ExcelReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let grandtotalberat = 0;
    this.props.dataExel.forEach((elem) => {
      grandtotalberat += elem.detail.reduce((a, b) => a + b.berat, 0);
    });
    return (
      <>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="ant-btn ant-btn-primary ant-btn-block ant-btn-success"
          table="table-to-xls"
          filename="LAPORAN GABUNG JO"
          sheet="LAPORAN GABUNG JO"
          buttonText="Export Excel"
        />
        <table
          id="table-to-xls"
          style={{ display: "none", borderCollapse: "collapse" }}
        >
          <thead>
            <tr>
              <th
                style={{
                  color: "#000",
                  textAlign: "center",
                }}
                colSpan="2"
              >
                LAPORAN GABUNG JO
              </th>
            </tr>
            <tr>
              <th
                style={{
                  color: "#000",
                  textAlign: "center",
                }}
                colSpan="2"
              >
                TANGGAL{" "}
                {this.props.dataHead?.tgl_awal +
                  " s/d " +
                  this.props.dataHead?.tgl_akhir}
              </th>
            </tr>
            <tr>
              <th
                style={{
                  backgroundColor: "#99CCFF",
                  color: "#000",
                  textAlign: "center",
                  border: "0.5px solid black",
                }}
              >
                NO POHON YANG DIGABUNG
              </th>
              <th
                style={{
                  backgroundColor: "#99CCFF",
                  color: "#000",
                  textAlign: "center",
                  border: "0.5px solid black",
                }}
              >
                BERAT
              </th>
            </tr>
          </thead>
          {this.props.dataExel.map((el) => {
            return (
              <>
                <thead>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#99CCFF",
                        color: "#000",
                        textAlign: "left",
                        border: "0.5px solid black",
                      }}
                    >
                      No Transaksi : {el.no_transaksi}
                    </th>
                    <th
                      style={{
                        backgroundColor: "#99CCFF",
                        color: "#000",
                        textAlign: "left",
                        border: "0.5px solid black",
                      }}
                    >
                      No Pohon : {el.no_pohon}
                    </th>
                  </tr>
                </thead>
                <thead>
                  <tr>
                    <th
                      colSpan={2}
                      style={{
                        backgroundColor: "#99CCFF",
                        color: "#000",
                        textAlign: "left",
                        border: "0.5px solid black",
                      }}
                    >
                      Jenis Bahan : {el.jenis_bahan}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {el.detail.map((item) => {
                    return (
                      <tr>
                        <td style={{ border: "0.5px solid black" }}>
                          {item.no_pohon}
                        </td>
                        <td style={{ border: "0.5px solid black" }}>
                          {item.berat}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <thead>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#99CCFF",
                        color: "#000",
                        textAlign: "left",
                        border: "0.5px solid black",
                      }}
                    >
                      Sub Total
                    </th>
                    <th
                      style={{
                        backgroundColor: "#99CCFF",
                        color: "#000",
                        textAlign: "right",
                        border: "0.5px solid black",
                      }}
                    >
                      {el.detail.reduce((a, b) => a + b.berat, 0)}
                    </th>
                  </tr>
                </thead>
              </>
            );
          })}
          <tfoot>
            <tr>
              <th
                style={{
                  backgroundColor: "#99CCFF",
                  color: "#000",
                  textAlign: "left",
                  border: "0.5px solid black",
                }}
              >
                Grand Total
              </th>
              <th
                style={{
                  backgroundColor: "#99CCFF",
                  color: "#000",
                  textAlign: "right",
                  border: "0.5px solid black",
                }}
              >
                {grandtotalberat}
              </th>
            </tr>
          </tfoot>
        </table>
      </>
    );
  }
}
export default ExcelReport;
