import React from "react";
import { useSelector, connect } from "react-redux";
import { Button, Row, Col } from "antd";
// import ui from "../../../../../application/selectors/ui";
import PembuatanJenisBahan from "../../../../../application/selectors/laporanpembuatanjenisbahan";
import ExcelReport from "./excel/excelReport";
import pdfReport from "./pdf/pdfReport";
import "antd/dist/antd.css";
import "antd-button-color/dist/css/style.css";
import pdfReportDetail from "./pdf/pdfReportDetail";
import ExcelReportDetail from "./excel/excelReportDetail";

const BtnPrint = () => {
  // eslint-disable-next-line
  const tipe = useSelector(
    (state) => state.form.FormLaporanPembuatanJenisBahan?.values.tipe_laporan
  );
  const dataPembuatanJenisBahan = useSelector(
    PembuatanJenisBahan.getAllLaporanPembuatanJenisBahan
  );
  const datahead = JSON.parse(
    localStorage.getItem("laporan_pembuatan_jenis_bahan")
  );

  const pdfExportHandle = () => {
    if (tipe === "REKAP") {
      pdfReport(dataPembuatanJenisBahan);
    } else {
      pdfReportDetail(dataPembuatanJenisBahan);
    }
  };

  return (
    <Row style={{ marginTop: 15 }}>
      <Col span={10} style={{ marginTop: 10 }}>
        {tipe === "REKAP" ? (
          <ExcelReport dataExel={dataPembuatanJenisBahan} dataHead={datahead} />
        ) : (
          <ExcelReportDetail
            dataExel={dataPembuatanJenisBahan}
            dataHead={datahead}
          />
        )}
      </Col>
      <Col htmltype="button" span={10} style={{ marginTop: 10 }} offset={2}>
        {tipe === "REKAP" ? (
          <Button
            type="primary"
            htmltype="button"
            block
            danger
            onClick={() => pdfExportHandle()}
          >
            Print To PDF
          </Button>
        ) : (
          <Button
            type="primary"
            htmltype="button"
            block
            danger
            onClick={() => pdfExportHandle()}
          >
            Print To PDF
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default connect()(BtnPrint);
