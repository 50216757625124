import { change } from "redux-form";
import {
  GET_DATA_BATU_LOCAL,
  GET_DETAIL_BATU_BY_KODE,
  POST_KIRIM_BATU_COR,
  SAVE_BATU_LOCAL,
  getDataBatuLocal,
  setDataBatuLocal,
} from "../actions/kirimbatucor";
import * as sweetalert from "../../infrastructure/shared/sweetalert";
import { setLoadingButton } from "../actions/ui";

const GETActionBatuCOR =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === GET_DETAIL_BATU_BY_KODE) {
      const dataBatu = getState().tambahambilbatu.feedback;
      const batuFind = dataBatu.find(
        (val) => val.kode_batu === action.payload.data
      );
      dispatch(change("FormDetailBatuCOR", "jumlah_batu", batuFind.jumlah));
      dispatch(
        change("FormDetailBatuCOR", "berat_batu", batuFind.berat?.toFixed(3))
      );
      dispatch(change("FormDetailBatuCOR", "nama_batu", batuFind.nama_batu));
    }
    if (action.type === GET_DATA_BATU_LOCAL) {
      const datalocal = getLocal("temp_kirim_batu_cor");
      if (datalocal === undefined || datalocal === null) {
        dispatch(setDataBatuLocal({ feedback: [] }));
      } else {
        dispatch(setDataBatuLocal({ feedback: datalocal }));
      }
    }
  };

const POSTActionBatuCOR =
  ({ api, log, writeLocal, getLocal, toast }) =>
  ({ dispatch, getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === SAVE_BATU_LOCAL) {
      const data = getState().form.FormDetailBatuCOR.values;
      const datalocal = getLocal("temp_kirim_batu_cor");
      if (datalocal === undefined || datalocal === null) {
        const newArr = [];
        newArr.push(data);
        writeLocal("temp_kirim_batu_cor", newArr);
        sweetalert.default.Success("Berhasil Menambahkan Data !");
        window.location.reload();
      } else {
        const cek = datalocal.find((val) => val.kode_batu === data.kode_batu);
        if (cek !== undefined) {
          sweetalert.default.Failed("Data Sudah Ada Pada Tabel");
        } else {
          datalocal.push(data);
          writeLocal("temp_kirim_batu_cor", datalocal);
          sweetalert.default.Success("Berhasil Menambahkan Data !");
          window.location.reload();
        }
      }
    }
    if (action.type === POST_KIRIM_BATU_COR) {
      dispatch(setLoadingButton(true));
      const datalocal = getLocal("temp_kirim_batu_cor");
      const onSend = {
        detail_batu: [],
      };
      datalocal.forEach((element) => {
        onSend.detail_batu.push({
          kode_batu: element.kode_batu,
          nama_batu: element.nama_batu,
          qty: parseInt(element.jumlah_kirim),
          berat: parseFloat(element.berat_kirim),
        });
      });
      api.KirimBatuCOR.addKirimBatuCOR(onSend).then((res) => {
        if (res.value !== null) {
          dispatch(setLoadingButton(false));
          localStorage.removeItem("temp_kirim_batu_cor");
          sweetalert.default.Success(
            res.value.message || "Berhasil Menambahkan Data !"
          );
          dispatch(getDataBatuLocal);
        } else {
          dispatch(setLoadingButton(false));
          sweetalert.default.Failed(
            res.error?.data.message || "Gagal Menambahkan Data !"
          );
        }
      });
    }
  };

const data = [GETActionBatuCOR, POSTActionBatuCOR];

export default data;
