import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Form, Button, Row, Col, Select } from "antd";
import { Field, reduxForm } from "redux-form";
import moment from "moment";
import styleAntd from "../../../../../infrastructure/shared/styleAntd";
import ui from "../../../../../application/selectors/ui";
import "antd/dist/antd.css";
import {
  getAllLaporanPembuatanJenisBahan,
  setDataLaporanPembuatanJenisBahanSuccess,
} from "../../../../../application/actions/laporanpembuatanjenisbahan";
import JenisBahan from "../../../../../application/selectors/masterjenisbahan";

const dateFormat = "DD/MM/YYYY";
const today = new Date();
const { Option } = Select;

const maptostate = (state) => {
  if (state.form.FormLaporanPembuatanJenisBahan?.values !== undefined) {
    return {
      initialValues: {
        date: state.form.FormLaporanPembuatanJenisBahan?.values.date,
        kode_jenis_bahan:
          state.form.FormLaporanPembuatanJenisBahan?.values.kode_jenis_bahan,
        tipe_laporan:
          state.form.FormLaporanPembuatanJenisBahan?.values.tipe_laporan,
      },
    };
  } else {
    return {
      initialValues: {
        date: [moment(today, dateFormat), moment(today, dateFormat)],
        kode_jenis_bahan: "SEMUA",
        tipe_laporan: "REKAP",
      },
    };
  }
};

let FormLaporanPembuatanJenisBahan = (prop) => {
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const btnLoading = useSelector(ui.getBtnLoading);
  const dataJenisBahan = useSelector(JenisBahan.getAllMasterJenisBahan);
  return (
    <Form layout="vertical">
      <Row>
        <Col span={4} style={{ marginRight: 20 }}>
          <Field
            name="date"
            type="date"
            label={<span style={{ fontSize: "13px" }}>Tanggal</span>}
            component={styleAntd.ARangePick}
            className="form-item-group"
            onBlur={(e) => e.preventDefault()}
          />
        </Col>
        <Col span={4} style={{ marginRight: 20 }}>
          <Field
            showSearch
            name="kode_jenis_bahan"
            label={<span style={{ fontSize: "13px" }}>Kode Jenis Bahan</span>}
            style={{ width: "100%" }}
            component={styleAntd.ASelect}
            placeholder="Pilih Kode Jenis Bahan"
            onBlur={(e) => e.preventDefault()}
          >
            <Option value="SEMUA" key="SEMUA">
              <span style={{ fontSize: "13px" }}>SEMUA</span>
            </Option>
            {dataJenisBahan.map((item) => {
              return (
                <Option
                  value={item.kode_jenis_bahan}
                  key={item.kode_jenis_bahan}
                >
                  <span style={{ fontSize: "13px" }}>
                    {item.nama_jenis_bahan + " (" + item.kode_jenis_bahan + ")"}
                  </span>
                </Option>
              );
            })}
          </Field>
        </Col>
        <Col span={4}>
          <Field
            showSearch
            name="tipe_laporan"
            label={<span style={{ fontSize: "13px" }}>Tipe Laporan</span>}
            style={{ width: "100%" }}
            component={styleAntd.ASelect}
            placeholder="Pilih Tipe Laporan"
            onBlur={(e) => e.preventDefault()}
            onChange={() =>
              dispatch(
                setDataLaporanPembuatanJenisBahanSuccess({ feedback: [] })
              )
            }
          >
            <Option value="REKAP" key="REKAP">
              <span style={{ fontSize: "13px" }}>REKAP</span>
            </Option>
            <Option value="DETAIL" key="DETAIL">
              <span style={{ fontSize: "13px" }}>DETAIL</span>
            </Option>
          </Field>
        </Col>
        <Col offset={1}>
          <Button
            type="primary"
            htmltype="button"
            loading={btnLoading}
            onClick={() => prop.dispatch(getAllLaporanPembuatanJenisBahan)}
            style={{ marginTop: 29 }}
          >
            Lihat Laporan
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

FormLaporanPembuatanJenisBahan = reduxForm({
  form: "FormLaporanPembuatanJenisBahan",
  enableReinitialize: true,
})(FormLaporanPembuatanJenisBahan);
export default connect(maptostate, null)(FormLaporanPembuatanJenisBahan);
