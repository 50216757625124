import { jsPDF } from "jspdf";
import "jspdf-autotable";

const pdfReportDetail = (data = "") => {
  const doc = new jsPDF("p", "mm", [297, 210]);
  const datahead = JSON.parse(
    localStorage.getItem("laporan_pembuatan_jenis_bahan")
  );
  let tableRows = [];
  let tableColumn = [];

  let finalY = 30;
  doc.text("Laporan Pembuatan Jenis Bahan Detail", 14, 15);
  doc.setFontSize(20);
  doc.text("PRODUKSI", 155, 15);

  doc.setFontSize(10);
  doc.setProperties({
    title: "Pembuatan Jenis Bahan Detail",
  });
  doc.text(`PERIODE : ${datahead.tgl_awal} s/d ${datahead.tgl_akhir}`, 14, 25);

  tableColumn = [
    // [
    //   {
    //     content: `NO`,
    //   },
    //   {
    //     content: `NAMA BAHAN`,
    //   },
    //   {
    //     content: `BERAT`,
    //   },
    //   {
    //     content: `KADAR`,
    //   },
    //   {
    //     content: `24K`,
    //   },
    // ],
  ];

  let totalBerat = 0;
  let totalKarat24 = 0;
  data.forEach((element) => {
    const rowKirim = [
      {
        content: "Tanggal : " + element.tanggal,
        styles: {
          halign: "left",
          fillColor: "#E8E5E5",
          fontStyle: "bold",
        },
        colSpan: 2,
      },
      {
        content: "No Pohon : " + element.no_pohon,
        styles: {
          halign: "left",
          fillColor: "#E8E5E5",
          fontStyle: "bold",
        },
        // colSpan: 3,
      },
    ];
    tableRows.push(rowKirim);
    const rowKirimSecond = [
      {
        content: "No Transaksi : " + element.no_transaksi,
        styles: {
          halign: "left",
          fillColor: "#E8E5E5",
          fontStyle: "bold",
        },
        colSpan: 2,
      },
      {
        content: "Jenis Bahan : " + element.nama_jenis_bahan,
        styles: {
          halign: "left",
          fillColor: "#E8E5E5",
          fontStyle: "bold",
        },
        // colSpan: 3,
      },
    ];
    tableRows.push(rowKirimSecond);
    const rowHead = [
      {
        content: `NO`,
        styles: {
          halign: "center",
          fillColor: "#bbbbbb",
          fontStyle: "bold",
        },
      },
      {
        content: `NAMA BAHAN`,
        styles: {
          halign: "center",
          fillColor: "#bbbbbb",
          fontStyle: "bold",
        },
      },
      {
        content: `BERAT`,
        styles: {
          halign: "center",
          fillColor: "#bbbbbb",
          fontStyle: "bold",
        },
      },
      // {
      //   content: `KADAR`,
      //   styles: {
      //     halign: "center",
      //     fillColor: "#bbbbbb",
      //     fontStyle: "bold",
      //   },
      // },
      // {
      //   content: `24K`,
      //   styles: {
      //     halign: "center",
      //     fillColor: "#bbbbbb",
      //     fontStyle: "bold",
      //   },
      // },
    ];
    tableRows.push(rowHead);
    element.detail_bahan.forEach((item, index) => {
      const row = [
        {
          content: index + 1,
        },
        {
          content: item.nama_bahan,
        },
        {
          content: item.berat?.toFixed(3),
          styles: {
            halign: "right",
          },
        },
        // {
        //   content: item.kadar,
        //   styles: {
        //     halign: "right",
        //   },
        // },
        // {
        //   content: item.karat_24?.toFixed(3),
        //   styles: {
        //     halign: "right",
        //   },
        // },
      ];
      tableRows.push(row);
    });
    const rowfooter24k = [
      {
        content: "24K",
        styles: {
          halign: "right",
          fillColor: "#bbbbbb",
          fontStyle: "bold",
        },
        colSpan: "2",
      },
      {
        content: element.karat_24?.toFixed(3),
        styles: {
          halign: "right",
          fillColor: "#bbbbbb",
          fontStyle: "bold",
        },
      },
      // {
      //   content: "",
      //   styles: {
      //     halign: "right",
      //     fillColor: "#bbbbbb",
      //     fontStyle: "bold",
      //   },
      // },
      // {
      //   content: element.detail_bahan
      //     .reduce((a, b) => a + parseFloat(b.karat_24), 0)
      //     ?.toFixed(3),
      //   styles: {
      //     halign: "right",
      //     fillColor: "#bbbbbb",
      //     fontStyle: "bold",
      //   },
      // },
    ];
    tableRows.push(rowfooter24k);
    const rowfooter = [
      {
        content: "Sub Total",
        styles: {
          halign: "right",
          fillColor: "#bbbbbb",
          fontStyle: "bold",
        },
        colSpan: "2",
      },
      {
        content: element.detail_bahan
          .reduce((a, b) => a + parseFloat(b.berat), 0)
          ?.toFixed(3),
        styles: {
          halign: "right",
          fillColor: "#bbbbbb",
          fontStyle: "bold",
        },
      },
      // {
      //   content: "",
      //   styles: {
      //     halign: "right",
      //     fillColor: "#bbbbbb",
      //     fontStyle: "bold",
      //   },
      // },
      // {
      //   content: element.detail_bahan
      //     .reduce((a, b) => a + parseFloat(b.karat_24), 0)
      //     ?.toFixed(3),
      //   styles: {
      //     halign: "right",
      //     fillColor: "#bbbbbb",
      //     fontStyle: "bold",
      //   },
      // },
    ];
    tableRows.push(rowfooter);
    totalBerat += element.detail_bahan.reduce(
      (a, b) => a + parseFloat(b.berat),
      0
    );
    totalKarat24 += element.karat_24;
  });

  const footer24k = [
    {
      content: "Total 24K",
      styles: {
        halign: "right",
        fillColor: "#bbbbbb",
        fontStyle: "bold",
      },
      colSpan: "2",
    },
    {
      content: totalKarat24?.toFixed(3),
      styles: {
        halign: "right",
        fillColor: "#bbbbbb",
        fontStyle: "bold",
      },
    },
  ];
  tableRows.push(footer24k);
  const footer = [
    {
      content: "Grand Total",
      styles: {
        halign: "right",
        fillColor: "#bbbbbb",
        fontStyle: "bold",
      },
      colSpan: "2",
    },
    {
      content: totalBerat?.toFixed(3),
      styles: {
        halign: "right",
        fillColor: "#bbbbbb",
        fontStyle: "bold",
      },
    },
  ];
  tableRows.push(footer);

  const printed = [
    {
      content: `Printed By Admin`,
      colSpan: 3,
      styles: {
        fontStyle: "italic",
        textColor: "#000",
        halign: "left",
      },
    },
  ];
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: finalY,
    theme: "plain",
    margin: { top: 10 },
    bodyStyles: {
      fontSize: 8,
      halign: "center",
    },
    headStyles: {
      fontSize: 8,
      fillColor: "#E8E5E5",
      textColor: "#000",
      valign: "middle",
      halign: "center",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  tableRows = [];
  tableColumn = [];
  finalY = doc.autoTableEndPosY() + 20;

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(10);
  for (let j = 1; j < pages + 1; j += 1) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  const string = doc.output("bloburl");
  const x = window.open();
  x.document.open();
  x.document.write(
    `<html>
    <head>
    <title>Pembuatan Jenis Bahan Detail</title>
    </head>
    <body style='margin:0 !important'>
    <embed width='100%' height='100%'src='${string}'></embed>
    </body>
    </html>`
  );
};

export default pdfReportDetail;
