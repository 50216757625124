import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Divider, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  Panel,
  PanelHeader,
  PanelBody,
  PanelFooter,
} from "./../../components/panel/panel.jsx";
import FormKirimBatuCOR from "../../components/admin-berlian/kirim-batu-cor/button-add-kirim-batu-cor";
import TableKirimBatuCOR from "../../components/admin-berlian/kirim-batu-cor/table-kirim-batu-cor";
import { getSaldoBatu } from "../../../application/actions/tambahambilbatu.jsx";
import {
  getDataBatuLocal,
  kirimBatuCOR,
} from "../../../application/actions/kirimbatucor.jsx";
import ui from "../../../application/selectors/ui";

const KirimBatu = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSaldoBatu);
    dispatch(getDataBatuLocal);
  }, [dispatch]);
  const isLoading = useSelector(ui.getBtnLoading);
  return (
    <div>
      <ol className="breadcrumb float-xl-right">
        <li className="breadcrumb-item">
          <Link to="/dashboard">Home</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/admin-berlian/kirim-batu-cor">Admin Berlian</Link>
        </li>
        <li className="breadcrumb-item active">Kirim Batu COR</li>
      </ol>
      <h1 className="page-header">
        Admin Berlian <small>Kirim Batu COR</small>
      </h1>
      <Panel>
        <PanelHeader>Kirim Batu COR</PanelHeader>
        <PanelBody>
          <Card bordered={false}>
            <div className="row">
              <div className="col-12">
                <FormKirimBatuCOR />
              </div>
            </div>
            <div
              className="row"
              style={{
                marginTop: 10,
              }}
            >
              <div className="col-12">
                <Divider orientation="left" style={{ fontSize: "14px" }}>
                  Tabel Kirim Batu COR
                </Divider>
              </div>
              <div className="col-12">
                <TableKirimBatuCOR />
              </div>
            </div>
          </Card>
        </PanelBody>
        <PanelFooter>
          <div className="row">
            <div className="col-1">
              <Button
                type="primary"
                loading={isLoading}
                onClick={() => dispatch(kirimBatuCOR)}
              >
                Simpan
              </Button>
            </div>
            <div className="col-1">
              <Button
                type="danger"
                onClick={() => {
                  localStorage.removeItem("temp_kirim_batu_cor");
                  window.location.reload();
                }}
              >
                Batal
              </Button>
            </div>
          </div>
        </PanelFooter>
      </Panel>
    </div>
  );
};

export default KirimBatu;
