import { jsPDF } from "jspdf";
import "jspdf-autotable";

const pdfReport = (data = []) => {
  const doc = new jsPDF("p", "mm", "a4");
  const datahead = JSON.parse(localStorage.getItem("laporan_gabung_jo"));
  let tableRows = [];
  let tableColumn = [];

  let finalY = 30;
  doc.text("Laporan Gabung JO", 14, 15);
  doc.setFontSize(20);
  doc.text("PRODUKSI", 160, 15);

  doc.setFontSize(10);
  doc.setProperties({
    title: "Gabung JO",
  });
  doc.text(`PERIODE : ${datahead.tgl_awal} s/d ${datahead.tgl_akhir}`, 14, 25);

  let grandTotalBerat = 0;
  tableColumn = [
    [
      {
        content: `No Pohon Yang Digabung`,
      },
      {
        content: `Berat`,
      },
    ],
  ];
  data.forEach((el) => {
    const row = [
      {
        content: `No Transaksi : ${el.no_transaksi}`,
        styles: {
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
      {
        content: `No Pohon : ${el.no_pohon}`,
        styles: {
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
    ];
    tableRows.push(row);
    const rowjenisbahan = [
      {
        content: `Jenis Bahan : ${el.jenis_bahan}`,
        colSpan: 2,
        styles: {
          fillColor: "#E8E5E5",
          textColor: "#000",
        },
      },
    ];
    tableRows.push(rowjenisbahan);
    el.detail.forEach((item) => {
      const rowbody = [
        { content: item.no_pohon },
        {
          content: item.berat,
          styles: { halign: "right" },
        },
      ];
      tableRows.push(rowbody);
    });
    const rowsubtotal = [
      {
        content: "Sub Total",
        styles: { fillColor: "#E8E5E5", textColor: "#000" },
      },
      {
        content: el.detail.reduce((a, b) => a + b.berat, 0)?.toFixed(3),
        styles: {
          fillColor: "#E8E5E5",
          textColor: "#000",
          halign: "right",
        },
      },
    ];
    tableRows.push(rowsubtotal);
    grandTotalBerat += el.detail.reduce((a, b) => a + b.berat, 0);
  });

  const footer = [
    {
      content: "Grand Total",
      styles: { fillColor: "#E8E5E5", textColor: "#000" },
    },
    {
      content: grandTotalBerat?.toFixed(3),
      styles: {
        fillColor: "#E8E5E5",
        textColor: "#000",
        halign: "right",
      },
    },
  ];
  tableRows.push(footer);

  const printed = [
    {
      content: `Printed By Admin`,
      colSpan: 2,
      styles: {
        fontStyle: "italic",
        textColor: "#000",
        halign: "left",
      },
    },
  ];
  tableRows.push(printed);

  doc.autoTable({
    head: tableColumn,
    body: tableRows,
    startY: finalY,
    theme: "grid",
    margin: { top: 10 },
    bodyStyles: {
      fontSize: 8,
      halign: "left",
    },
    headStyles: {
      fontSize: 8,
      fillColor: "#E8E5E5",
      textColor: "#000",
      valign: "middle",
      halign: "center",
    },
    tableLineColor: [255, 255, 255],
    tableLineWidth: 1,
  });
  tableRows = [];
  tableColumn = [];
  finalY = doc.autoTableEndPosY() + 20;

  const pages = doc.internal.getNumberOfPages();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  doc.setFontSize(10);
  for (let j = 1; j < pages + 1; j += 1) {
    const horizontalPos = pageWidth / 2;
    const verticalPos = pageHeight - 10;
    doc.setPage(j);
    doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, {
      align: "center",
    });
  }
  const string = doc.output("bloburl");
  const x = window.open();
  x.document.open();
  x.document.write(
    `<html>
    <head>
    <title>Gabung JO</title>
    </head>
    <body style='margin:0 !important'>
    <embed width='100%' height='100%'src='${string}'></embed>
    </body>
    </html>`
  );
};

export default pdfReport;
